import { useEffect, useState } from 'react';
import { BaseModal, ModalNotification } from '../../../components';
import { useFetch } from '../../../hooks';
import { issueAlertStorage } from './section3';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const ModalAlert = ({ issue_id, preLoading }) => {
  const { user } = useAuth();
  const { ALERT } = useFetch();
  const { iALERTS, isetALERTS } = issueAlertStorage();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState();

  const createAlert = () => {};

  const handleSubmit = e => {
    e.preventDefault();
    setisLoading(true);
    const data = new FormData(e.currentTarget);
    const name = data?.get('name')?.toString() + '';
    const expired_at = data?.get('expired_at')?.toString() + '';
    const description = data?.get('description')?.toString() + '';

    const obj = { name, expired_at, description, user_id: user?.id };
    if (issue_id) {
      ALERT()
        .awaitCreate(obj, issue_id)
        .then(res => {
          let newARR = [res, ...iALERTS];
          isetALERTS(newARR);
          navigate(0);
        })
        .catch(err => alert(err))
        .finally(() => setisLoading(false));
    }
  };

  return (
    <BaseModal id="modalAlert">
      <BaseModal.Title>
        <span className="fw-semibold fs-6 text-secondary">Nueva</span> <span className="fw-semibold">ALERTA</span>
      </BaseModal.Title>
      <BaseModal.Body>
        {/* ID de la solicitud: <strong>{issueID}</strong> */}
        <form onSubmit={handleSubmit}>
          <BaseModal.Row>
            <BaseModal.Label htmlFor="name">Nombre</BaseModal.Label>
            <BaseModal.Input preloading={preLoading} id="name" name="name" defaultValue={''} />
          </BaseModal.Row>
          <BaseModal.Row>
            <BaseModal.Label htmlFor="name">Vencimiento</BaseModal.Label>
            <BaseModal.Input preloading={preLoading} id="name" name="expired_at" required defaultValue={''} type="datetime-local" />
          </BaseModal.Row>
          <BaseModal.Row>
            <BaseModal.Label htmlFor="name">Descripción</BaseModal.Label>
            <BaseModal.Textarea name="description" id=""></BaseModal.Textarea>
            {/* <BaseModal.Input preloading={preLoading} id="name" name="name" required defaultValue={''} /> */}
          </BaseModal.Row>
          <button type={!isLoading ? 'submit' : 'button'} className="btn btn-primary float-end py-0 px-4 mt-3 fw-semibold rounded-5 text-uppercase">
            {isLoading ? <div className="spinner-border spinner-border-sm fw-normal" role="status"></div> : 'Programar'}
          </button>
        </form>
      </BaseModal.Body>
    </BaseModal>
  );
};

export default ModalAlert;
