import React, { useEffect, useState } from 'react';
import OutlineHeading from '../components/outline-heading';
import Page12Module from '../modules/page1-2';
import MainTemplate from '../templates/main/main.template';
import { useLocation } from 'react-router-dom';
import SubHeaderWithFilters from '../components/subheader-with-filters';

const RequestsPage = () => {
  const location = useLocation();
  const [title, setTitle] = useState('Solicitudes');

  useEffect(() => {
    let split = location.pathname.split('/');
    setTitle(split[split.length - 1] || 'Solicitudes')
  }, [location]);

  return (
    <MainTemplate>
      <OutlineHeading title="Mantenimiento" />

      <SubHeaderWithFilters
        filterZone
        filterStation
        filterMonth
        filterPriority
        title={title} />

      <Page12Module />
    </MainTemplate>
  );
};
export default RequestsPage;
