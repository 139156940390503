import { create } from 'zustand';
import {useTools, useFetch} from './';

const useHistory = () => {
  const { isetLOG, iLOG } = issueLogStorage();
  const { LOG } = useFetch();
  const FIELDS = {
    selectStation: 'La estación',
    selectType: 'El tipo',
    selectPriority: 'La urgencia',
    selectDay: 'El horario',
    selectWarranty: 'La garantía 1',
    selectWarranty2: 'La garantía 2',
    selectIssueLevel: 'El tipo de siniestro',
    selectCharge: 'La imputación',
    selectSupplier: 'El proveedor',
    txtRO: 'El RO ha comentado: "',
    txtRM: 'El RM ha comentado: "',
    checkPRL: 'PRL activo',
    inputNetValue: 'El importe neto',
    inputTax: 'El valor de impuestos',
    inputTotal: 'El valor total',
    startedAt: 'La fecha de ejecución',
    plannedAt: 'La fecha de planeación',
    ro_approve: 'Aprobación del RO',
    prl_signature: 'La firma del PRL',
    dn_signature: 'La firma del DN',
    rm_changed: 'RM',
    quality_control: 'Control de calidad',
    [undefined]: '-',
  };
  const ACTIONS = {
    UPDATE: 'UPDATE',
  };
  const MESSAGES = {
    UPDATE: ' se ha modificado a: ',
  };

  const addLOG = (action, { issue_id, user_id, user_role, user_name, field, new_value }) => {
    let validateDuplicates = iLOG.some(element=> element?.new_value === new_value && element?.field === field);
    if (action === 'UPDATE' && !validateDuplicates) {
      let description = FIELDS[field] + MESSAGES['UPDATE'] + new_value;
      // Acá crear el log en base de datos
      let obj = { issue_id, user_id, user_role, user_name, field, new_value, description }
      LOG().awaitCreate(obj, issue_id).then(res=>{
        let newARR = [res, ...iLOG];
        isetLOG(newARR);
      })
    }
  };

  return { addLOG, isetLOG, iLOG };
};

//        _
//       ( )_
//   ___ | ,_)   _    _ __   _ _    __     __
// /',__)| |   /'_`\ ( '__)/'_` ) /'_ `\ /'__`\
// \__, \| |_ ( (_) )| |  ( (_| |( (_) |(  ___/
// (____/`\__)`\___/'(_)  `\__,_)`\__  |`\____)
//                               ( )_) |
//                                \___/'
export const issueLogStorage = create(set => ({
  iLOG: [],
  // iaddLOG: val => set(() => ({ iLOG: [val, ...iLOG] })),
  isetLOG: val => set(() => ({ iLOG: val })),
}));


export default useHistory;
