import styled from "styled-components";
import { useTools } from "../../hooks";

const PrintSection = ({id, children, ...rest}) => {
  const { printSection } = useTools()
  return (<span
    style={{display:'inline-block'}}
    {...rest}
  >
    <Container onClick={e => {
      printSection(e, id);
    }}>
      <IconPrint />
      <WrapperChildren >
        {children}
      </WrapperChildren>
    </Container>
  </span>)
}

const Container = styled.div`
  cursor: pointer;
  text-align: center;
`
const IconPrint = styled.span`
  display: block;
  width: 2.2rem;
  height: 1.2rem;
  background-image : url('/img/icons/printer.svg');
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 50%;
  background-color: #77508D;
  border-radius: 10rem;
  margin: auto;
`

const WrapperChildren = styled.span`
  display: block; 
  font-weight: 600; 
  font-size: .6em; 
  margin-top: .15rem;
`

export default PrintSection