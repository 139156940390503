import React, { useEffect, useState } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import styled from 'styled-components';
import { useFetch, useTools } from '../../../hooks';
import { PrintSection, Select } from '../../../components';
import { useGlobalSettingsStore } from '../../../config/store';

const TableOverviewModule = ({ data, ...rest }) => {
  const DATA = useLoaderData();
  // const { OVERVIEW_DATA } = DATA;
  const [OVERVIEW_DATA, setOVERVIEW_DATA] = useState([]);
  const [arrIssuesByStation, setArrIssuesByStation] = useState(['', '', '']);
  const [monthSelected, selectMonth] = useState(0);
  const { formatEUR, formatMonthDay } = useTools();
  const [itemSelected, selectItem] = useState();
  const MONTHS = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];

  useEffect(() => setOVERVIEW_DATA(data), [data]);

  return (
    <span className="d-xxl-flex flex-fill">
      {/* Los totales suman: {OVERVIEW_DATA.reduce((a,o)=>a + o.total_value, 0)} */}
      <section {...rest} style={{ overflowX: 'scroll', overflowY: 'hidden' }} className={'w-100'} id="PRINT_OVERVIEW">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" style={{ width: '70%' }}>
                ESTACIÓN
              </th>
              <th scope="col" className="text-center">
                Nº SOLICITUDES
              </th>
              <th scope="col" className="text-center">
                TOTAL
              </th>
              <th scope="col" className="text-center">
                GARANTÍA
              </th>
              <th scope="col" className="text-center">
                SEGURO
              </th>
              <th scope="col" className="text-center">
                TOTAL AÑO
              </th>
            </tr>
          </thead>

          <StyledTableBody>
            {OVERVIEW_DATA.map((v, i) => (
              <React.Fragment key={'DATA-' + i}>
                <tr
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    itemSelected !== v ? selectItem(v) : selectItem(undefined);
                  }}
                >
                  <th scope="row">{v.station_name}</th>
                  <td style={{ textAlign: 'center' }}>
                    <strong>{v.count_issues}</strong>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <strong>{formatEUR(v.total_value || 0)}</strong>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <strong>{formatEUR(v.waranty_value || 0)}</strong>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <strong>{formatEUR(v.insurance_value || 0)}</strong>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <strong>{formatEUR(v.total_value || 0)}</strong>
                  </td>
                </tr>
                {/* {
                    v === itemSelected && (
                    )
                  } */}
                <span style={{}}>
                  <td colSpan="1" style={{ padding: 0 }}>
                    <div style={{ transition: 'height .3s', height: v === itemSelected ? '400px' : 0, overflow: 'hidden' }}>
                      {/* {TableMonthList(i, OVERVIEW_DATA, setOVERVIEW_DATA={setOVERVIEW_DATA})} */}
                      <TableMonthList
                        parent={i}
                        monthSelected={monthSelected}
                        setOVERVIEW_DATA={(_, i) => {
                          setOVERVIEW_DATA(_);
                          selectMonth(i);
                        }}
                        OVERVIEW_DATA={OVERVIEW_DATA}
                      />
                    </div>
                  </td>
                  <td colSpan="5" style={{ padding: 0, marginTop: -1, position: 'relative' }}>
                    <div className={'px-2 w-100'} style={{ transition: '.3s', position: 'absolute', top: '20%', overflow: 'hidden', opacity: v === itemSelected ? 1 : 0 }}>
                      <h3 className={'pt-3 fs-6 fw-semibold ps-2'}>{MONTHS[monthSelected]} 2023</h3>
                      <table className="table table-borderless" style={{ fontSize: '.87em' }}>
                        <tbody>
                          {!v.issues[monthSelected] && <>Sin contenido</>}
                          {v.issues[monthSelected]?.map((v, i) => (
                            <tr key={'month-' + i}>
                              <td className={'pt-3 border-end-0'} style={{ color: 'black' }}>
                                <strong className="mt-3">{i < 10 && '0' + (i + 1)}</strong>
                              </td>
                              <td className={'py-1 border-end-0'}>
                                Nº Pedido:
                                <br />
                                <Link to={v.id ? '/solicitudes/' + v.id : '#'}>
                                  <strong>{v.order_id || 'PENDIENTE'}</strong>
                                </Link>
                                {/* <strong>{v.order_id || 'PENDIENTE'}</strong> */}
                              </td>
                              <td className={'py-1 border-end-0'}>
                                Día: <br /> <strong>{formatMonthDay(v.created_at)}</strong>
                              </td>
                              <td className={'py-1 border-end-0'}>
                                Coste: <br />
                                <strong>{formatEUR(v.total_value || 0)}</strong>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </span>
              </React.Fragment>
            ))}
          </StyledTableBody>
        </table>
      </section>
      <PrintSection className="mt-5 mx-3" id="PRINT_OVERVIEW">
        IMPRIMIR
      </PrintSection>
    </span>
  );
};

const StyledTableBody = styled.tbody`
  box-shadow: 0 0 0.5rem #c8c8c8;
`;

const TableMonthList = ({ parent, monthSelected, OVERVIEW_DATA, setOVERVIEW_DATA }) => {
  const { formatEUR } = useTools();

  const [ARR_MONTHS, setARR_MONTHS] = useState([
    { month: 'ENERO', requests: 0, total: 0 },
    { month: 'FEBRERO', requests: 0, total: 0 },
    { month: 'MARZO', requests: 0, total: 0 },
    { month: 'ABRIL', requests: 0, total: 0 },
    { month: 'MAYO', requests: 0, total: 0 },
    { month: 'JUNIO', requests: 0, total: 0 },
    { month: 'JULIO', requests: 0, total: 0 },
    { month: 'AGOSTO', requests: 0, total: 0 },
    { month: 'SEPTIEMBRE', requests: 0, total: 0 },
    { month: 'NOVIEMBRE', requests: 0, total: 0 },
    { month: 'DICIEMBRE', requests: 0, total: 0 },
  ]);

  useEffect(() => {
    const issues = OVERVIEW_DATA[parent].issues;
    let positionMissues = Object.keys(issues);
    let result = [...ARR_MONTHS];
    positionMissues.forEach(monthNumber => {
      const total = issues[monthNumber].reduce((a, o) => a + o.total_value, 0);
      result[monthNumber] = { ...result[monthNumber], requests: issues[monthNumber].length || 0, total: total };
    });
    setARR_MONTHS(result);
  }, [monthSelected]);

  return (
    <>
      <div className="d-flex align-items-center my-3 mx-3 mx-xxl-5">
        <span style={{ fontSize: '.75em' }} className={'fw-semibold col-7 text-end pe-3'}>
          AÑO
        </span>
        <Select options={[{ value: '2023', name: '2023' }]} className="rounded border"></Select>
      </div>
      <div className="px-3 px-xxl-5">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="border-end-0"></th>
              <th scope="col" className="text-center border-end-0">
                SOLICITUDES
              </th>
              <th scope="col" className="text-center border-end-0">
                TOTAL
              </th>
            </tr>
          </thead>
          <tbody>
            {ARR_MONTHS.map((v, i) => (
              <tr
                key={'{s' + i}
                className={`custom-hover ${monthSelected === i && 'active-row'}`}
                role="button"
                onClick={() => {
                  let ARR = OVERVIEW_DATA;
                  ARR[parent].localSelectedMonth = i;
                  ARR = [...ARR];
                  setOVERVIEW_DATA(ARR, i);
                }}
              >
                <th scope="row" className={`border-end-0 py-0`}>
                  {v.month}
                </th>
                <td className="text-center border-end-0 py-0 fw-semibold">{v.requests}</td>
                <td className="text-center border-end-0 py-0 fw-semibold">{formatEUR(v.total)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableOverviewModule;
