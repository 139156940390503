import { StyledSection2 } from '../styles';
import { ArticleBordered } from '../../../components';
// ..HOOKS
import { useTools } from '../../../hooks';
import { useEffect } from 'react';
import { useState } from 'react';
import { useGlobalSettingsStore } from '../../../config/store';

export const SECTION2 = ({ zone_object, ...rest }) => {
  const [zone, setZone] = useState({});
  const [monthSelected, setMonthSelected] = useState(0);
  const {globalSettings} = useGlobalSettingsStore();
  const MONTH = ['ENERO','FEBRERO','MARZO','ABRIL','MAYO','JUNIO','JULIO','AGOSTO','SEPTIEMBRE','OCTUBRE','NOVIEMBRE','DICIEMBRE']
  useEffect(() => setZone(zone_object), [zone_object]);
  useEffect(()=> {setMonthSelected(globalSettings.month -1 || 0)},[globalSettings])
  const { formatEUR } = useTools();
  return (
    <StyledSection2 style={{ flex: 0 }} {...rest}>
      <ArticleBordered>
        <ArticleBordered.Head title={'RESUMEN MES'} />
        <div className="px-3 py-2">
          <Tlt>{MONTH[monthSelected]}</Tlt>
          <Subtl>PRESUPUESTO: {formatEUR(zone?.month_budget_zone || 0)}</Subtl>
          <Line>
            <span>PRESUPUESTO CONSUMIDO: </span>
            <strong>{zone.month_budget_zone ? formatEUR(zone?.total_value_issues || 0) : 0} | {zone.month_budget_zone ? parseFloat((zone.total_value_issues*100)/zone.month_budget_zone).toFixed() : 0}%</strong>
          </Line>
          <Line>
            <span>No DE SOLICITUDES: </span>
            <strong>{zone?.issues_month || 0}</strong>
          </Line>
          <Line>
            <span>CON GARANTÍA: </span>
            <strong>0 | {formatEUR(0)}</strong>
          </Line>
          <Line>
            <span>CON SEGURO: </span>
            <strong>0 | {formatEUR(0)}</strong>
          </Line>
        </div>
      </ArticleBordered>
      <ArticleBordered>
        <ArticleBordered.Head title={'RESUMEN ANUAL'} />
        <div className="px-3 py-2">
          <Tlt>2023</Tlt>
          <Subtl>TOTAL: {formatEUR(zone?.year_budget_zone || 0)}</Subtl>
          <Line>
            <span>Nº DE SOLICITUDES: </span>
            <strong>{zone?.issues_year || 0}</strong>
          </Line>
          <Line>
            <span>CON GARANTÍA: </span>
            <strong>0 | {formatEUR(0)}</strong>
          </Line>
          <Line>
            <span>CON SEGURO: </span>
            <strong>0 | {formatEUR(0)}</strong>
          </Line>
        </div>
      </ArticleBordered>
    </StyledSection2>
  );
};

const Tlt = ({ children }) => <h3 style={{ fontSize: '.9em', fontWeight: '600' }}>{children}</h3>;
const Subtl = ({ children }) => <h2 style={{ fontSize: '1.3em', fontWeight: '600' }}>{children}</h2>;
const Line = ({ children }) => <div style={{ fontSize: '.8em' }}>{children}</div>;
