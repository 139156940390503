import { useState } from 'react';
import { useGlobalSettingsStore, useIssueStore } from '../../config/store';
import SolidHeading from '../solid-heading';
import { useFetch } from '../../hooks';
import { useEffect } from 'react';
import { showAlert } from '../notification-component';
import { useLocation, useSearchParams } from 'react-router-dom';

const SelectPRIORITY = ({ onChange }) => {
  const { globalSettings } = useGlobalSettingsStore();
  const OPTIONS = [
    { id: 'null', name: 'TODAS', disabled: false },
    { id: 'LEVE', name: 'LEVE' },
    { id: 'MEDIO', name: 'MEDIO' },
    { id: 'URGENTE', name: 'URGENTE' },
    { id: 'CRITICO', name: 'CRÍTICO' },
  ];

  return <SolidHeading.InputContainer label={'URGENCIA'} options={OPTIONS} defaultValue={globalSettings?.priority} onChange={e => onChange('priority', e)} />;
};

export const SelectZONE = ({ onChange }) => {
  const [lZones, setLZones] = useState([]);
  const { ZONE } = useFetch();
  const { globalSettings } = useGlobalSettingsStore();

  useEffect(() => {
    ZONE()
      .lZones()
      .then(res => setLZones(res));
  }, []);
  return <SolidHeading.InputContainer label={'ZONA'} options={[{ id: 'all', name: 'TODAS', disabled: false }, ...lZones]} defaultValue={globalSettings?.zone} onChange={e => onChange('zone', e)} />;
};

export const SelectSTATION = ({ onChange }) => {
  const [lStations, setLStations] = useState([]);
  const { ZONE, STATION } = useFetch();
  const { globalSettings } = useGlobalSettingsStore();

  useEffect(() => {
    if(globalSettings.zone && globalSettings.zone !== 'all' && globalSettings.station !== 'null'){
      loadStations(globalSettings.zone)
    }else{
      STATION()
      .lStations()
      .then(res => setLStations(res));
    }
  }, [globalSettings]);
  
  const loadStations = (id) => {
    const { awaitStations } = ZONE(id);
    awaitStations().then(res=>setLStations(res));
  }

  return <SolidHeading.InputContainer label={'ESTACIÓN'} options={[{ id: 'all', name: 'TODAS', disabled: false }, ...lStations]} defaultValue={globalSettings?.station} onChange={e => onChange('station', e)} />;
};

export const SelectMonth = ({ onChange }) => {
  const { globalSettings } = useGlobalSettingsStore();
  const date = new Date();
  const [stateInput, setInput] = useState();
  useEffect(() => {
    if (!isNaN(globalSettings.month && date)) {
      let month = globalSettings.month < 10 ? '0' + globalSettings.month : globalSettings.month;
      setInput(date.getFullYear() + '-' + month);
    } else {
      setInput('');
    }
  }, [globalSettings, date]);

  return (
    <SolidHeading.InputContainer selector={false} label={'MES'}>
      <input type="month" defaultValue={stateInput} name="" id="" onChange={onChange} />
    </SolidHeading.InputContainer>
  );
};

const SubHeaderWithFilters = ({ title, filterZone, filterStation, filterMonth, filterPriority, children }) => {
  const { ISSUE } = useFetch();
  const { globalSettings, setGlobalSettings } = useGlobalSettingsStore();
  const { setIssues } = useIssueStore();
  const location = useLocation();

  const handleChange = (filter, e) => {
    const valEvent = e.target?.value || e;
    if (valEvent) {
      ISSUE()
        .lIssues({ [filter]: valEvent, action: 'refresh' })
        .then(res => {
          let list = res.data;
          let complement = [];
          let i = 0;
          if (list.length > 0) {
            if (list.length < 10) {
              do {
                i++;
                complement.push({});
              } while (i < 10 - list.length);
            }
            setIssues([...list, ...complement]);
          } else {
            showAlert();
            setIssues(null);
          }
        });
    }
    if(filter !== 'page'){
      setGlobalSettings({ ...globalSettings, [filter]: valEvent });
    }else{
      setGlobalSettings({ ...globalSettings, page: 1 });
    }
  };

  const changeDate = e => {
    let date = new Date(e.target.value);
    date = date.getUTCMonth() + 1;
    handleChange('month', date);
  };

  useEffect(() => {
    const OPT = { aprobadas: 'DONE', planificadas: 'SCHEDULED', ejecutadas: 'INPROGRESS', desestimadas: 'DECLINED' };
    let split = location.pathname.split('/');
    let action = OPT[split[split.length - 1]] || undefined;
    action = action || 'null';
    handleChange('status', action);
  }, [location]);

  useEffect(() => {
    if (!isNaN(objLocation().page)) {
      handleChange('page', objLocation().page);
    }
  }, [location]);

  const objLocation = () => {
    let splitSearch = location.search.split('?' && '&').map(_ => _.replace('?', '').split('='));
    const obj = Object.fromEntries(splitSearch);
    return obj;
  };

  return (
    <SolidHeading title={title}>
      <div className='d-flex'>
        {filterZone && <SelectZONE onChange={handleChange} />}
        {filterStation && <SelectSTATION onChange={handleChange} />}
        {filterMonth && <SelectMonth onChange={changeDate} />}
        {filterPriority && <SelectPRIORITY onChange={handleChange} />}
      </div>
      {children}
    </SolidHeading>
  );
};
export default SubHeaderWithFilters;
