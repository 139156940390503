import styled from 'styled-components';
export const OkCancelGroup = ({ txtOk, txtCancel, onClickOk, onClickCancel, id }) => {
  return (
    <StyledWrapper>
      {onClickOk && (
        <span>
          <StyledCheck />
          <strong id={id} role="button" onClick={onClickOk}>
            {txtOk || 'FIRMAR'}
          </strong>
        </span>
      )}
      {onClickCancel && (
        <span>
          <StyledCancel />
          <strong id={id} role="button" onClick={onClickCancel}>{txtCancel || 'DENEGAR'}</strong>
        </span>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.span`
  width: 100%;
  display: block;
  float: right;
  text-align: right;
  padding-right: 1em;
  position: relative;
  z-index: 2;
  & > span {
    display: inline-block;
    min-width: 8em;
    &:not(:first-child) {
      margin-top: 1rem;
    }
  } 
  & strong{
    font-size: .66rem;
  }
`;

const StyledCheck = styled.span`
  mask-image: url('/img/icons/check-rounded.svg');
  background-color: #3ea536;
  width: 1.6rem;
  height: 1.6em;
  mask-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
`;

const StyledCancel = styled.span`
  mask-image: url('/img/icons/cancel-rounded.svg');
  background-color: #cd1719;
  width: 1.6rem;
  height: 1.6em;
  mask-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
`;
