import { useEffect, useState } from 'react';
import { StyledArticleNotifications, StyledArticleRequestPhotos, StyledLineNotification, PNStyledTitle } from '../../styles';
import { useFetch } from '../../../../hooks';
import { create } from 'zustand';

export const issueAlertStorage = create(set => ({
  iALERTS: [],
  isetALERTS: val => set(() => ({ iALERTS: val })),
}));

/**
 * START NOTIFICATIONS
 * @param {*} param0
 * @returns
 */
export const ArticleNotifications = ({ children }) => {
  return <StyledArticleNotifications>{children}</StyledArticleNotifications>;
};
export const PanelNotifications = ({ issue_id, children, ...rest }) => {
  //   const ARR = [
  //     {
  //       name: 'NUEVA SOLICITUD POR FIRMAR',
  //       icon: null,
  //       color: "#FF8F43",
  //       expired_at: ' - 20 / 06 / 2022 - 17:02',
  //       description: "Nueva alerta"
  //     },
  //     {
  //         name: 'SEGUNDA ALERTA',
  //         icon: null,
  //         color: "#D74F38",
  //         expired_at: ' - 20 / 06 / 2022 - 17:02',
  //         description: "Nueva alerta"
  //       },
  //   ];

  const [ARR, setARR] = useState([]);
  const { ALERT } = useFetch();
  const { iALERTS, isetALERTS } = issueAlertStorage();
  const today = new Date().getTime();

  useEffect(() => {
    ALERT()
      .byIssue(issue_id)
      .then(res => isetALERTS(res));
  }, []);

  return (
    <div style={{ height: '14.7rem', overflowY: 'scroll' }} className={'mt-4 mb-5 me-22 ps-3 pe-4'} {...rest}>
      {iALERTS.map((v, i) => (
        <StyledLineNotification key={'value-' + i} style={{ paddingTop: '.3rem', paddingBottom: '.55rem', marginLeft: '1rem' }}>
          {/* <PNStyledTitle style={{ fontSize: '.78em' }} color={v.color}> */}
          <PNStyledTitle style={{ fontSize: '.78em' }} color={v.expired_at > today?'red':'#777777'}>
            {v.name?.toString().toUpperCase()}
            <span> {v?.expired_at}</span>
          </PNStyledTitle>
          <br />
          <span className="body">{v?.description}</span>
        </StyledLineNotification>
      ))}
    </div>
  );
};
/**
 * END NOTIFICATIONS
 */

export const ArticlePhotos = ({ children }) => {
  return <StyledArticleRequestPhotos>{children}</StyledArticleRequestPhotos>;
};

export const DivPhotos = ({ ARR_IMGS, imgSelected, onSelect }) => {
  return (
    <div>
      {ARR_IMGS.map((v, i) => (
        <div
          data-bs-toggle="modal"
          onClick={e => {
            onSelect(v);
            e.preventDefault();
          }}
          data-bs-target="#photoModal"
          key={'.sd-' + i}
          style={{
            display: 'inline-block',
            margin: '.2rem',
            borderRadius: '.5rem',
            overflow: 'hidden',
            border: '1px solid lightgray',
            width: '4rem',
            height: '4rem',
          }}
        >
          <img src={v} alt="Imagen adjunta" width="60" />
        </div>
      ))}
    </div>
  );
};
