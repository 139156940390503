import { useState } from 'react';
import MainTemplate from '../templates/main/main.template';
import { StyledContainerPage17 } from '../modules/page1-7/styles';

// ..SECTIONS
import { SECTION1, SECTION2 } from '../modules/page1-7';
import SubHeaderWithFilters from '../components/subheader-with-filters';
import OutlineHeading from '../components/outline-heading';
import { useFetch, useTools } from '../hooks';
import { useGlobalSettingsStore } from '../config/store';
import { useEffect } from 'react';

const OverviewPage = () => {
  const [OVERVIEW_DATA, setOVERVIEW_DATA] = useState([]);
  const [zone_object, setzone_object] = useState({});
  const { globalSettings } = useGlobalSettingsStore();
  const { ISSUE } = useFetch();

  useEffect(() => {
    ISSUE()
      .awaitSummary(globalSettings)
      .then(res => {
        let zone = { total_value_issues: res?.total_value_issues, ...res?.zone };
        setOVERVIEW_DATA(res?.issues);
        setzone_object(zone);
      });
    // TODO: finally
  }, [globalSettings]);

  const { formatEUR } = useTools();
  return (
    <MainTemplate>
      <OutlineHeading title="Resumen" />
      <SubHeaderWithFilters filterZone filterStation filterMonth>
        {zone_object.month_budget_zone && (
          <div className="d-flex justify-content-between">
            <span className="fw-normal fs-5 bg-primary">SALDO TOTAL {formatEUR(zone_object.total_value_issues)}</span>
            <span className="fw-normal fs-5 px-5">DISPONIBLE {formatEUR(zone_object.month_budget_zone - zone_object.total_value_issues)}</span>
            <span className="fw-normal fs-5">{parseFloat((zone_object.total_value_issues*100)/zone_object.month_budget_zone).toFixed()}% Usado</span>
          </div>
        )}
      </SubHeaderWithFilters>
      <StyledContainerPage17 className="d-block d-xxl-flex px-sm-2 px-lg-0 gap-1">
        <SECTION1 data={OVERVIEW_DATA} />
        <SECTION2 className="justify-content-xxl-end" zone_object={zone_object} />
      </StyledContainerPage17>
    </MainTemplate>
  );
};

export default OverviewPage;
