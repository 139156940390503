import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import {
  StyledLink,
  StyledSubheaderUsers,
  SpanCheckboxContainer,
  StyledContainerTable
} from './styles';
import { useTools } from '../../../hooks';
import { SmallButton } from '../../../components';
import Section1Page22Modal from './modal';
import ModalNotification from '../../../components/modal-notification';

// .hooks
import { useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';

export const Section1Page22 = () => {
  const location = useLocation();
  const { formatDate } = useTools();
  const userType = location.hash?.split('#')[1] || '';
  const [ARR_USERS, setARR_USERS] = useState([]);
  const navigate = useNavigate();
  const { USER } = useFetch();
  const [userSelected, setUserSelected] = useState();

  useEffect(() => {
    location.hash || navigate('/users#admin');
    const splitHash = location.hash.split('#')[1]
    const storedUSERS = window.sessionStorage.getItem(splitHash);
    const objUSERS = JSON.parse(storedUSERS) || [{name: "Recargar"}]
    setARR_USERS(objUSERS)
  }, [location, navigate, userType]);

  /**
   * @deprecated
   */
  // const handleChange = (n, e, p, zones, stations, ro) => {
  //   let arr = [];
  //   let date = new Date()
  //   date = date.getTime()
  //   arr = [...ARR_USERS, { name: n, email: e, created_at: date }]
  //   // CREATE
  //   if(!userSelected){
  //     USER().awaitCreate(userType,{ name: n, email: e, role: userType.toUpperCase(), password: p, zones: (zones || null), stations: (stations || null), ro: (ro || null) })
  //     .then(res=>{setARR_USERS(res); navigate(0)})
  //     .catch(err=> alert(err))
  //   }
  //   // UPDATE
  //   else{
  //     USER(userSelected).awaitUpdate({name: n, email: e, role: userType, password: (p||undefined), zones: (zones || null), stations: (stations || null), ro: (ro || null) })
  //     .then(()=> setTimeout(()=>{navigate(0)},200))
  //   }
  // };
  /**
   * END @deprecated
   */

  const handleCheck = (id, value) => {
    let a = []
    a = [...ARR_USERS]
    a[id] = {...a[id], checked : value}
    setARR_USERS(a)
  }

  const handleDeleteRecord = () => {
    let a = []
    a = ARR_USERS
    const newARR = a.filter(i=> !i.checked);
    const checked = a.filter(i=> i.checked);
    // asyncDEL(checked)
    USER().awaitDelete(userType,checked)
      .then(res=>{
        if(res === 'success'){
        setARR_USERS([...newARR])
      }})
    document.getElementById("checkUser").checked = false;
  }

  return (
    <>
      <Section1Page22.Subheader location={location} />
      <div className="d-flex flex-wrap align-items-start">
        <StyledContainerTable>
          <table className="table fw-semibold" style={{fontSize:'1em'}}>
            <tbody>
              {ARR_USERS?.map((v, i) => (
                <tr key={'tr-' + i}
                  onClick={()=>{
                    setUserSelected(v.id);
                  }}
                  // data-modal-type={v.id}
                >
                  <td>
                    <Section1Page22.CheckboxName
                      name={v.name}
                      id={(i < 9 ? '0' : null) + (i + 1)}
                      onChecked={(e)=>handleCheck(i,e)}
                      checked={v.checked}
                    />
                  </td>
                  <td>{v.email}</td>
                  <td>{formatDate(v.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </StyledContainerTable>
        <div className='mx-sm-4'>
          <SmallButton
            icon="+"
            onClick={()=>{setUserSelected(undefined)}}
            data-bs-toggle="modal"
            data-bs-target="#createResourceModal"
            data-modal-type={""}
          >
            AÑADIR
          </SmallButton>
          <SmallButton icon="-" data-bs-toggle="modal" data-bs-target="#modalNotification" className={"px-3"}>ELIMINAR</SmallButton>
          <ModalNotification 
            title={"AVISO"} 
            description={`¿Está seguro de que desea eliminar ${ARR_USERS.filter(i=> i.checked).length === 1?'el usuario seleccionado':'los usuarios seleccionados'}?`} 
            btnSend={"Eliminar"} 
            onClickSend={(e)=>{handleDeleteRecord()}}/>
        </div>
      </div>
      <Section1Page22Modal 
        userType={userType} 
        userSelected={userSelected} 
        ARR_USERS={ARR_USERS}  
        setARR_USERS={res => setARR_USERS(res)} />
    </>
  );
};

Section1Page22.Subheader = function Section1Page22Subheader({ location, ...rest }) {
  const OPTS = [
    { hash: '#admin', name: 'Administradores' },
    { hash: '#dn', name: 'Directores' },
    { hash: '#rro', name: 'RRO' },
    { hash: '#ro', name: 'RO' },
    { hash: '#rm', name: 'RM' },
    { hash: '#prl', name: 'PRL' }
  ];
  return (
    <StyledSubheaderUsers className='mb-4'>
      {OPTS?.map((v, i) => (
        <StyledLink
          to={v.hash}
          key={'{StyledSubheaderUsers.LINK}' + i}
          className={`${location.hash === v.hash && 'active'}`}
        >
          {v.name}
        </StyledLink>
      ))}
    </StyledSubheaderUsers>
  );
};

Section1Page22.CheckboxName = function Section1Page22CheckboxName({
  name,
  id,
  onChecked,
  checked = false
}) {
  return (
    <SpanCheckboxContainer>
      <input
        className="form-check-input rounded-5"
        type="checkbox"
        checked={checked}
        onChange={(e)=>{onChecked(e.target.checked)}}
        id={"checkUser"}
        name={"checkUser"}
      />
      <span
      data-bs-toggle="modal"
      data-bs-target="#createResourceModal"
      >
          {id} - {name}
      </span>
    </SpanCheckboxContainer>
  );
};



export default Section1Page22;