import MainTemplate from "../templates/main/main.template";
import { SettingsModules } from "../modules/page3-1";


const SettingsPage = () => {
    return (
    <MainTemplate>
        <SettingsModules />
    </MainTemplate>
    )
}



export default SettingsPage;