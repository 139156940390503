import React, { useState } from "react";
import styled from "styled-components";

export default function Select({ options, onValue, onBlur, value, dict, ...rest }) {
  const handleChange = (e) => {
    const item = options.find(({name})=> name === e.currentTarget.value);
    onValue({name: e.currentTarget.value, id: (item?.id || undefined)});
    // return {name: e.currentTarget.value, id: (item?.id || undefined)}
  }
  return <StyledInputContainer style={{width:'100%', paddingLeft:'.2rem'}} {...rest}>
    <select defaultValue={value} onBlur={onBlur} onChange={handleChange} style={{width:'100%',  fontWeight: '400', fontSize: '.9rem', textOverflow: 'ellipsis', paddingRight:'1rem'}} {...rest}>
      {(value ? <option>{value}</option> : <option disabled value={"empty"}></option>)}
      {options.map((v,i)=>{
        if (dict){
          if (value?.toString() !== dict[v?.name]){
            return (<option key={v?.id+"-"+i} id={v?.id} name={v?.name?.toString()}>{dict[v?.name]}</option>)
          }
        }else{
          if(value?.toString() !== v.name?.toString()){
            return (<option key={v?.id+"-"+i} id={v?.id} name={v?.name?.toString()}>{v?.name?.toString().toUpperCase()}</option>)
          }
        }
      })}
    </select>
  </StyledInputContainer>
}

export const StyledInputContainer = styled.div`
  font-size: .85rem;
  position: relative;
  & select, & input{
    cursor: pointer;
    background-color: transparent;
    font-weight: 600;
    appearance: none;
    border: none;
    outline: none;
  }

  &[disabled]{
    select{
      color: black;
      cursor: default;
    }
  }

  &:not([disabled]){
    &:before{
      content: "";
      mask-image : url("/img/icons/arrow-down.svg");
      transform: rotate(135deg);
      background-color: #575756;
      mask-repeat: no-repeat;
      width: .55rem;
      height: .55rem;
      position: absolute;
      right: .66rem;
      bottom: .6em;
      pointer-events: none;
    }
  }



`