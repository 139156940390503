import { StyledContainer, StyledCol, StyledFormContainer } from './styles';
// ..STORE
import { useStorePhotos } from './store';
// ..COMPONENTS
import { ArticleBordered, PrintSection, SmallButton } from '../../components';
// ..SECTIONS
import { ARTICLE_1, ARTICLE_2 } from './sections/section1/article2';
import HistorySection from './sections/section2';
import { DivPhotos, PanelNotifications } from './sections/section3';
// import { DivPhotos, PanelNotifications } from './sections/third-section';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { issueStore } from '../../config/store';
import useFetch from '../../hooks/useFetch';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HeaderSection1 from './sections/section1/header';
import { useHistory } from '../../hooks';
import ModalAlert from './sections/modalAlert';

const NewRequestTemplate = ({ idIssue }) => {
  const [isLoading, setLoading] = useState(true);
  const { ARR_IMG1, ARR_IMG2 } = useStorePhotos();
  const [imgSelected, selectImg] = useState();
  const { user } = useAuth();
  const { ISSUE, LOG, SETTINGS } = useFetch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { iLOG, isetLOG, addLOG } = useHistory();

  const { issueState, setIssueState } = issueStore();

  useEffect(() => {
    SETTINGS().lSettings();
  }, []);

  useEffect(() => {
    if (!params.id) {
      if (issueState.id) {
        setTimeout(() => navigate(0), 100);
      } else {
        let newIssue = { zone_id: user.zone?.id, ro_id: user?.id, status: 'PENDING', ...issueState }; // Condicional para otros roles
        if (issueState.us) {
          ISSUE()
            .awaitCreate(newIssue)
            .then(res => navigate('/solicitudes/' + res?.id))
            .catch(err => alert(err))
            .finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      }
    }
  }, [issueState]);

  useEffect(() => {
    if (issueState?.station_id) {
      console.log('TIENE ESTACiÖN');
    }
  }, [issueState]);

  useEffect(() => {
    if (idIssue) {
      ISSUE(idIssue)
        .awaitInfo()
        .then(res => {
          setIssueState(JSON.parse(res));
          LOG()
            .byIssue(idIssue)
            .then(res => isetLOG(res));
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  }, [idIssue]);

  /** @return Unmount */
  useEffect(() => {
    return () => {
      window.sessionStorage.removeItem('issues');
    };
  }, []);

  useEffect(() => {
    console.log(issueState.rm_id);
    if (user.role.toLowerCase() === 'rm' && !issueState.rm_id) {
      setIssueState({ ...issueState, rm_id: user.id, rm_name: user.name });
    }
  }, [issueState]);

  const handleSave = e => {
    if (issueState.id && e) {
      setLoading(true);
      ISSUE(idIssue)
        .awaitUpdate(issueState)
        .then(res => setAndLog(res, e))
        .catch(err => alert(err))
        .finally(() => {
          setLoading(false);
          e.target.blur();
        });
    }
  };

  const handleForceSave = (e, parsedState, forcedValue) => {
    let event = e;
    event.target.value = forcedValue || '';
    const id = event.target.id;
    if (id && parsedState) {
      setLoading(true);
      ISSUE(idIssue)
        .awaitUpdate(parsedState)
        .then(res => setAndLog(res, event))
        .catch(err => alert(err))
        .finally(() => {
          setLoading(false);
          e.target.blur();
        });
    } else {
      alert('ERROR: \nHan faltado algunos valores para almacenar la información');
    }
  };

  const setAndLog = (res, event) => {
    setIssueState(res);
    addLOG('UPDATE', { issue_id: idIssue, user_name: user?.name, user_id: user?.id, user_role: user?.role, new_value: event.target.value, field: event.target.id });
  };

  return (
    <StyledContainer>
      {/* Primera sección (Formulario para registrar solicitud) */}
      <StyledCol id="FORM" direction="row" className="formContainer placeholder-glow" style={{ gridArea: 's1', height: 'fit-content' }}>
        <StyledFormContainer className="border" id="PRINT_FORM" style={{ fontSize: '.8em' }}>
          <HeaderSection1 isLoading={isLoading} user={user}></HeaderSection1>
          <ARTICLE_1 userRole={user?.role} isLoading={isLoading} autoSave={handleSave} forceSave={handleForceSave} />
          <ARTICLE_2 userRole={user?.role} autoSave={handleSave} forceSave={handleForceSave} />
        </StyledFormContainer>
        <div>
          <PrintSection id="PRINT_FORM">IMPRIMIR</PrintSection>
        </div>
      </StyledCol>
      {/* Segunda sección (Historial) */}
      <StyledCol style={{ gridArea: 's2' }}>
        <ArticleBordered style={{ height: '100%' }}>
          <ArticleBordered.Head title={'HISTORIAL'}>
            <PrintSection id="HISTORY_SECTION" />
          </ArticleBordered.Head>
          <HistorySection id="HISTORY_SECTION" />
        </ArticleBordered>
      </StyledCol>
      {/* Tercera sección (Alertas e imágenes) */}
      <StyledCol style={{ gridArea: 's3' }}>
        <ArticleBordered className={'pb-2'}>
          <ModalAlert issue_id={issueState?.id} />
          <ArticleBordered.Head title={'ALERTAS'}>
            <button type="button" data-bs-toggle="modal" data-bs-target="#modalAlert" className="btn btn-primary">
              NUEVA ALERTA
            </button>
          </ArticleBordered.Head>
          <PanelNotifications issue_id={idIssue} />
        </ArticleBordered>
        <ArticleBordered>
          <ArticleBordered.Head title={'FOTOS SOLICITUD'} />
          {/* <DivPhotos ARR_IMGS={ARR_IMG1} onSelect={e => selectImg(e)} /> */}
          <DivPhotos ARR_IMGS={issueState?.imagesRO || []} onSelect={e => selectImg(e)} />
        </ArticleBordered>
        <ArticleBordered>
          <ArticleBordered.Head title={'FOTOS CONTROL CALIDAD'} />
          <DivPhotos ARR_IMGS={ARR_IMG2} onSelect={e => selectImg(e)} />
        </ArticleBordered>
      </StyledCol>
      {/* Ventana modal para visualizar fotos */}
      <div className="modal fade" id="photoModal" tabIndex="-1" aria-labelledby="photoModal" aria-hidden="true">
        <div className="modal-dialog">
          <img src={imgSelected} alt="Previsualización" />
        </div>
      </div>
    </StyledContainer>
  );
};

export default NewRequestTemplate;
