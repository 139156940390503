import { useEffect, useRef, useState } from "react";
import { useTools } from "../../hooks"

const InputEUR = ({name, onChange, value, ...rest}) => {

    const { formatEUR } = useTools();
    const [inputValue, setinputValue] = useState(formatEUR(0));
    const refInputEUR = useRef();

    useEffect(()=> setinputValue(value), [value])
    return (<input
    id="netValue"
    type="cel"
    className="text-center"
    style={{ textAlign: 'right', fontWeight: '600', paddingRight: '.3rem', display: 'block', width: '100%', fontSize: '1.2em' }}
    value={ inputValue }
    onKeyDown={e=> e.key === 'Enter' && e.currentTarget.blur()}
    onBlur={()=>{
        !isNaN(inputValue) && setinputValue(formatEUR(inputValue))}
    }
    name={name}
    onMouseDown={()=> setinputValue("")}
    ref={refInputEUR}
    onChange={e=>{
        let val = e.target.value;
        if(!isNaN(val)) { setinputValue(val); onChange(val) };
    }}
    {...rest}
    />);
}

export default InputEUR;