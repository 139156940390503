import { useState } from 'react'
import { HistoryList, HistoryItem } from '../../styles'
import { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useTools, useHistory } from '../../../../hooks'

const HistorySection = ({ ...rest }) => {

    const {formatDate} = useTools();
    const { iLOG } = useHistory();

    return <section {...rest}>
        <HistoryList style={{overflowY:'scroll', maxHeight: document.getElementById('FORM')?.getBoundingClientRect().height}}>
            {
                iLOG?.map((v, i) => (
                    <HistoryItem key={"hs-"+i} className="ps-3 py-22 placeholder-glow">
                        <strong className={`fw-bold ${!v.created_at && 'placeholder'} rounded-1 mb-1`}>{formatDate(v?.created_at)} </strong>
                        <span>{v?.description}</span>
                        <span><strong className='fw-bold'>{v?.user_role.toUpperCase()}:</strong> {v?.user_name}</span>
                    </HistoryItem>
                ))
            }
        </HistoryList>
    </section>
}

export default HistorySection;