import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { API, HEADERS } from '../constants';

export const useAuthStore = create(
  persist(
    set => ({
      user: {},
      token: undefined,
      setUser: val => set(() => ({ user: val })),
      setToken: val => set(() => ({ token: val })),
    }),
    {
      name: 'auth',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

const useAuth = () => {
  const { user, token, setToken, setUser } = useAuthStore();
  function SignInAccount(email, password) {
    return new Promise((resolve, reject) => {
      fetch(`${API}/api/login`, {
        method: 'POST',
        headers: HEADERS,
        body: JSON.stringify({
          name: 'false',
          email: email,
          password: password,
        }),
      })
        .then(response => {
          let body = response.json();
          body
            .then(res => {
              if (response.status === 200) {
                setToken(res.token);
                setUser({ ...res.user, zone: { ...res.zone } });
                resolve(res);
              } else {
                reject(res.message);
              }
            })
            .catch(err => reject(err.message));
        })
        .catch(err => reject(err.message));
    });
  }

  function logout(e) {
    e.preventDefault();
    sessionStorage.clear();
    localStorage.clear();
    setToken(undefined);
    window.location.reload();
  }

  return { user, token, SignInAccount, logout };
};

export default useAuth;
