export const StatusValues = () => {
    return {
        CRÍTICO : "#CD171A",
        CRITICO : "#CD171A",
        URGENTE : "#FF8300",
        MEDIO : "#FCD926",
        LEVE : "#027961"
    }
}

export const translStatus = () => {
    return {
        DONE : "PEDIDO FINALIZADO",
        WARNING: "ALERTA INCIDENCIA",
        PENDING: "PEDIDO A FIRMAR",
        SCHEDULED: "PEDIDO PROGRAMADO",
        INPROGRESS: "EN EJECUCIÓN",
        DECLINED: "DESESTIMADA",
    }
}

// export const API = "https://mantenimiento-backend.desarrolloplenoil.com"
export const API = "https://api.plenoil.grubbe.io"
// export const API = "http://localhost";
export const PARAMS = {
    mode: "no-cors",
    cache: "no-cache",
    credentials: "same-origin",
}
export const HEADERS = {
    'Content-Type': 'application/json',
    'Accept': "application/json"}