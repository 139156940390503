import { useBeforeUnload } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useAuth, useFetch, useTools } from '../../../../hooks';
import { StyledSection2, StyledArticle3, StyledArticle4, StyledSection5, SpanSignature, StyledSection6, StyledSection7 } from '../../styles';
import { UploadImage } from '../../utils/upload-photo';
import { OkCancelGroup } from '../../utils/okcancel-group';

// ..article 3
import { InputEUR } from '../../../../components';
import { issueStore, useGlobalSettingsStore } from '../../../../config/store';

export function ARTICLE_2({ userRole, autoSave, forceSave }) {
  const { user } = useAuth();
  const { formatDate } = useTools();
  const { globalSettings } = useGlobalSettingsStore();

  // ..STATES
  const [isRO, setisRO] = useState(false);
  const [isPRL, setisPRL] = useState(false);
  const [isDN, setisDN] = useState(false);
  const [isRM, setisRM] = useState(false);

  const { issueState, setIssueState } = issueStore();

  useEffect(() => {
    if (userRole.toLowerCase() === 'rm') setisRM(true);
    if (userRole.toLowerCase() === 'ro') setisRO(true);
    if (userRole.toLowerCase() === 'prl') setisPRL(true);
    if (userRole.toLowerCase() === 'dn') setisDN(true);
  }, [userRole]);

  /**
   * Unload
   * useBeforeUnload: Store datos del issue;
   * useEffect: Set state datos del storage;
   */
  useBeforeUnload(
    useCallback(() => {
      localStorage.idataSaved = window.btoa(JSON.stringify(issueState));
    }, [issueState])
  );

  /**
   * Onload
   */
  useEffect(() => {
    if (localStorage.idataSaved) {
      setIssueState(JSON.parse(window.atob(localStorage.idataSaved)));
    } else {
      let parsedState = { ...issueState, zone_id: user.zone?.id };
      setIssueState(parsedState);
    }
  }, []);

  const TtlSection = ({ center, children }) => {
    return <h3 style={{ textAlign: center ? 'center' : 'inherit', fontSize: '1rem', fontWeight: '600', marginTop: '.5rem', marginBottom: '.2rem' }}>{children}</h3>;
  };

  const DivText = ({ children, ...rest }) => {
    return (
      <div style={{ fontSize: '1em' }} {...rest}>
        {children}
      </div>
    );
  };

  return (
    <>
      {/* SECCIÓN 2 : DETALLE DE LA SOLICITUD */}
      <Section2>
        <TtlSection center>DETALLE DE LA SOLICITUD</TtlSection>
        <div style={{ position: 'relative' }}>
          <label className="tltFloat" htmlFor="txtRO">
            RO
          </label>
          <textarea
            name="txtRO"
            id="txtRO"
            value={issueState.text_ro || ' '}
            onBlur={autoSave}
            style={{ display: 'block', width: '100%' }}
            onChange={e => {
              let parsedState = { ...issueState, text_ro: e.target.value };
              setIssueState(parsedState);
            }}
            // disabled={!isRO && issueState.ro_id !== user.id }
            disabled={parseInt(issueState.ro_id) !== parseInt(user.id)}
          />
          {isRO && <UploadImage vector={'ARR1'} />}
        </div>
        <div style={{ position: 'relative' }}>
          <label className="tltFloat" htmlFor="txtRO">
            RM
          </label>
          <textarea
            name=""
            id="txtRM"
            value={issueState.text_rm || ' '}
            onBlur={autoSave}
            style={{ display: 'block', width: '100%' }}
            onChange={e => {
              let parsedState = { ...issueState, text_rm: e.target.value };
              setIssueState(parsedState);
            }}
            // disabled={!isRM && issueState.rm_id != user.id}
            disabled={parseInt(issueState.rm_id) !== parseInt(user.id)}
          />

          <div className="form-check" style={{ position: 'absolute', right: 0, bottom: -2, display: 'flex', alignItems: 'center', backgroundColor: '#B2B2B2', padding: '.24rem .5rem', borderRadius: '.3rem 0 .3rem 0', pointerEvents: 'none' }}>
            <label style={{ paddingRight: '.4rem', fontWeight: '500', color: '#575756' }} htmlFor="checkPRL">
              ACTIVAR PRL
            </label>
            <input
              id="checkPRL"
              name="checkPRL"
              role={parseInt(issueState.rm_id) === parseInt(user.id) ? 'button' : 'checkbox'}
              type="checkbox"
              checked={issueState.is_active_prl || false}
              onBlur={autoSave}
              onChange={e => {
                let parsedState = { ...issueState, is_active_prl: e.currentTarget.checked, status: e.currentTarget.checked ? 'PENDING' : '' };
                setIssueState(parsedState);
              }}
              style={{ pointerEvents: 'fill' }}
              disabled={parseInt(issueState.rm_id) !== parseInt(user.id)}
            />
          </div>
        </div>
      </Section2>

      {/* SEGUNDA PIEZA */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* SECCIÓN 3 : IMPORTE A PROVISIONAR... */}
        <Article3>
          <TtlSection center>IMPORTE A PROVISIONAR POR EL RESPONSABLE DE MANTENIMIENTO DE ZONA</TtlSection>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 650, margin: 'auto', flexWrap: 'wrap' }}>
            <span style={{ width: '20%', minWidth: '7rem', display: 'block', margin: '1% 2%' }} className="text-center">
              <label htmlFor="netValue" style={{ textAlign: 'right' }}>
                IMPORTE NETO
              </label>
              <InputEUR
                id="inputNetValue"
                name="inputNetValue"
                value={issueState.net_value || 0}
                onBlur={autoSave}
                onChange={e => {
                  let parsedState = { ...issueState, net_value: e, total_value: parseFloat(e) + parseFloat(issueState.tax_value) };
                  setIssueState(parsedState);
                }}
                disabled={parseInt(issueState.rm_id) !== parseInt(user.id)}
              />
            </span>
            <span style={{ width: '20%', minWidth: '7rem', display: 'block', margin: '1% 2%' }} className="text-center">
              <label htmlFor="netValue">IMPUESTOS</label>
              <InputEUR
                id="inputTax"
                name="inputTax"
                value={issueState.tax_value || 0}
                onBlur={autoSave}
                onChange={e => {
                  let parsedState = { ...issueState, tax_value: e, total_value: parseFloat(e) + parseFloat(issueState.net_value) };
                  setIssueState(parsedState);
                }}
                disabled={parseInt(issueState.rm_id) !== parseInt(user.id)}
              />
            </span>
            <span style={{ width: '20%', minWidth: '7rem', display: 'block', margin: '1% 2%' }} className="text-center">
              <label htmlFor="netValue">TOTAL</label>
              <InputEUR
                id="inputTotal"
                name="inputTotal"
                value={issueState.total_value || 0}
                onBlur={autoSave}
                onChange={e => {
                  let parsedState = { ...issueState, total_value: e };
                  setIssueState(parsedState);
                }}
                disabled={parseInt(issueState.rm_id) !== parseInt(user.id)}
              />
            </span>
            <span style={{ width: '23%', minWidth: '7rem', display: 'block', margin: '1% 2%' }} className="text-center">
              <label htmlFor="plannedAt" style={{ whiteSpace: 'nowrap' }}>
                FECHA DE PLANEACIÓN
              </label>
              <input
                id="plannedAt"
                type="date"
                className="text-center"
                value={issueState.planned_at || undefined}
                onBlur={autoSave}
                onChange={e => {
                  let date = e.target.value;
                  let parsedState = { ...issueState, planned_at: date, status: 'SCHEDULED' };
                  setIssueState(parsedState);
                }}
                disabled={parseInt(issueState.rm_id) !== parseInt(user.id)}
                style={{ fontWeight: '600', paddingRight: '.3rem', display: 'block', width: '100%', fontSize: '1.2em' }}
              />
            </span>
          </div>
        </Article3>

        {/* SECCIÓN 4 : AUTORIZACIÓN DIRECTOR NACIONAL... */}
        {issueState.total_value > globalSettings?.dn_limit ? (
          <Article4>
            <div style={{ width: '50%' }}>
              <TtlSection>AUTORIZACIÓN DIRECTOR NACIONAL</TtlSection>
              <DivText>
                <strong>NOMBRE DN: </strong>
                {issueState?.dn_name}
              </DivText>
              <DivText>
                <strong>CARGO: </strong> DIRECTOR NACIONAL
              </DivText>
              <DivText>
                <strong>FECHA: </strong>
                {issueState?.dn_id && formatDate(Date())}
              </DivText>
            </div>
            <SpanSignature style={{ position: 'relative' }}>
              {issueState.dn_approve != null ? (
                <>
                  {parseInt(issueState.dn_id) === parseInt(user.id) ? (
                    <>
                      <span style={{ position: 'absolute', fontSize: '20px', left: '32%', top: '10%' }}>{issueState?.dn_approve ? 'FIRMADO' : 'DENEGADO'}</span>
                      <span
                        style={{ position: 'absolute', top: '50%', left: '0', width: '100%', textAlign: 'center', textDecoration: 'underline' }}
                        role="button"
                        id="dn_signature"
                        onClick={e => {
                          let parsedState = { ...issueState, dn_id: null, dn_name: null, dn_approve: null };
                          setIssueState(parsedState);
                          forceSave(e, parsedState, 'PENDIENTE');
                        }}
                      >
                        Deshacer
                      </span>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <span style={{ position: 'absolute', fontSize: '20px', left: '32%', top: '10%' }}>PENDIENTE</span>
                </>
              )}
            </SpanSignature>
            {issueState.dn_approve === null && isDN ? (
              <OkCancelGroup
                id="dn_signature"
                onClickOk={e => {
                  let parsedState = { ...issueState, dn_id: user.id, dn_name: user.name, dn_approve: true };
                  setIssueState(parsedState);
                  forceSave(e, parsedState, 'FIRMADO');
                }}
                onClickCancel={e => {
                  let parsedState = { ...issueState, dn_id: user.id, dn_name: user.name, dn_approve: false };
                  setIssueState(parsedState);
                  forceSave(e, parsedState, 'DENEGADO');
                }}
              />
            ) : null}
          </Article4>
        ) : null}

        {/* SECCIÓN 5 : PRL */}
        {issueState.is_active_prl ? (
          <Section5>
            <div style={{ width: '50%' }}>
              <TtlSection>PRL</TtlSection>
              <DivText>
                <strong>NOMBRE PRL: </strong>
                {issueState?.prl_name}
              </DivText>
              <DivText>
                <strong>CARGO: </strong>
                {'PREVENCIÓN RIESGOS LABORALES'}
              </DivText>
              <DivText>
                <strong>FECHA: </strong>
                {issueState?.prl_id && formatDate(Date())}
              </DivText>
            </div>
            <SpanSignature style={{ position: 'relative' }}>
              {issueState.prl_approve ? (
                <>
                  <span style={{ position: 'absolute', fontSize: '20px', left: '32%', top: '10%' }}>FIRMADO</span>
                  {parseInt(issueState.prl_id) === parseInt(user.id) ? (
                    <span
                      style={{ position: 'absolute', top: '50%', left: '0', width: '100%', textAlign: 'center', textDecoration: 'underline' }}
                      role="button"
                      id="prl_signature"
                      onClick={e => {
                        let parsedState = { ...issueState, prl_approve: null, prl_id: null, prl_name: null };
                        setIssueState(parsedState);
                        forceSave(e, parsedState, 'PENDIENTE');
                      }}
                    >
                      Deshacer
                    </span>
                  ) : null}
                </>
              ) : (
                <span style={{ position: 'absolute', fontSize: '20px', left: '32%', top: '10%' }}>PENDIENTE</span>
              )}
            </SpanSignature>
            {!issueState.prl_approve && isPRL ? (
              <OkCancelGroup
                id="prl_signature"
                onClickOk={e => {
                  let parsedState = { ...issueState, prl_id: user.id, prl_name: user.name, prl_approve: true };
                  setIssueState(parsedState);
                  forceSave(e, parsedState, 'FIRMADO');
                }}
              />
            ) : null}
          </Section5>
        ) : null}

        {/* SPLIT */}
        {issueState?.ro_approve ? (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '1rem' }} className="mt-2">
            {/* // {/* SECCIÓN 6 (1/2) : CONTROL DE CALIDAD */}
            <Section6 style={{ flex: 1 }}>
              <TtlSection center>CONTROL DE CALIDAD</TtlSection>
              <DivText>
                <strong>NOMBRE RO:</strong>
                <span>{issueState.quality_control ? issueState.ro_name : ''}</span>
              </DivText>
              <DivText>
                <strong>CARGO:</strong>
                <span>RESPONSABLE DE OPERACIONES</span>
              </DivText>
              <DivText>
                <strong>FECHA: {issueState.quality_control ? formatDate(issueState.ended_at) : ''}</strong>
              </DivText>
              <div style={{ position: 'absolute', bottom: '1.5rem', left: '3rem' }}>
                <UploadImage vector={'ARR2'} />
              </div>
              {issueState?.quality_control !== null ? (
                <>
                  {issueState.quality_control ? 'FIRMADO' : 'DENEGADO'}
                  <span
                    style={{ position: 'absolute', top: '50%', left: '0', width: '100%', textAlign: 'center', textDecoration: 'underline' }}
                    role="button"
                    id="quality_control"
                    onClick={e => {
                      let parsedState = { ...issueState, quality_control: null };
                      setIssueState(parsedState);
                      forceSave(e, parsedState, 'PENDIENTE');
                    }}
                  >
                    Deshacer
                  </span>
                </>
              ) : (
                parseInt(issueState.ro_id) === parseInt(user.id) && (
                  <OkCancelGroup
                    id="quality_control"
                    onClickOk={e => {
                      let date = new Date();
                      date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                      let parsedState = { ...issueState, quality_control: true, ended_at: date };
                      setIssueState(parsedState);
                      forceSave(e, parsedState, 'FIRMADO');
                    }}
                    onClickCancel={e => {
                      let parsedState = { ...issueState, quality_control: false };
                      setIssueState(parsedState);
                      forceSave(e, parsedState, 'DENEGADO');
                    }}
                  />
                )
              )}
            </Section6>
            {/* // {/* SECCIÓN 7 (1/2) : ...CASO DE DIFERENCIA... */}
            <Section7>
              <TtlSection center>COMPLETAR EN CASO DE DIFERENCIA EN IMPORTE DE FACTURA</TtlSection>
              <DivText style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <strong>FECHA FACTURA</strong>
                <input type="text" />
              </DivText>
              <DivText style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <strong className="text-end lh-1">IMPORTE NETO EN FACTURA</strong>
                <input type="text" />
              </DivText>
              <DivText style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <strong>TOTAL</strong>
                <input type="text" />
              </DivText>
              <hr />
              <DivText>
                <strong>NOMBRE RM CONFORMIDAD:</strong>
                <span> </span>
              </DivText>
              <DivText>
                <strong>FECHA:</strong>
                <span> </span>
              </DivText>
              <OkCancelGroup />
            </Section7>
          </div>
        ) : null}
      </div>
    </>
  );
}

// TODO: Agrupar acticles
const Section2 = ({ children }) => {
  return <StyledSection2>{children}</StyledSection2>;
};

const Article3 = ({ children }) => {
  return <StyledArticle3 style={{ flex: 1, width: '100%', padding: '.2rem .8rem' }}>{children}</StyledArticle3>;
};

const Article4 = ({ children }) => {
  return (
    <StyledArticle4 className="w-100 pt-1 pb-2 mt-22 row" style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
      {children}
    </StyledArticle4>
  );
};

const Section5 = ({ children }) => {
  return (
    <StyledSection5 className="w-100 pt-1 pb-2 mb-22 mt-22 row" style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
      {children}
    </StyledSection5>
  );
};

const Section6 = ({ children }) => {
  return <StyledSection6 style={{ flex: 1, position: 'relative' }}>{children}</StyledSection6>;
};

const Section7 = ({ children }) => {
  return <StyledSection7 style={{ flex: 1, backgroundColor: '#E5E7EB', position: 'relative', paddingRight: '1rem' }}>{children}</StyledSection7>;
};

export { default as ARTICLE_1 } from './article1';
