import styled from "styled-components";

export const StyledOutlineHeading = styled.div`
  border-bottom: 1.5px solid #A8A8A7;
  margin-bottom: 1.7rem;
  flex-wrap: wrap;
  align-items: end;
  justify-content: space-between;
`
export const SpanTitle = styled.span`
  flex:0;
  text-transform: uppercase;
  color: #575756;
  font-weight: 600;
  width: fit-content;
  // font-size: calc(1.5rem + .3vmax);
  font-size:2em;
`

export const StyledHorizontalList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin:0;
  font-size: .8rem;
  @media (max-width:1200px){
    width: 100%;
    padding-left: 0;
    padding-bottom: 1rem;
  }
`
export const StyledItem = styled.li`
  display: flex;
  align-items: center;
  position: relative;
  list-style: none;
  text-transform: uppercase;
  border-left: 1px solid #A8A8A7;
  @media (max-width: 600px){
    font-size: calc(.6em + 1vw);
    margin-top: 1em;
  }
  `

export const SpanNumber = styled.span`
  // font-size: 3vmax;
  font-size: 3.6em;
  height: 0;
  margin-top : calc(3.4em * -0.5);
`

export const StyledLastItems = styled.ul`

  li{
    list-style: none;
    
    &:not(:first-child){
      margin-left: 1rem;
    }
    
    &>small.label{
      text-transform: uppercase;
      font-size: .6em;
    }

    &>span{
      display: inline-flex;
      width: 1.2rem;
      height: 1.2rem;
      vertical-align:middle;
      align-items: center;
      justify-content: center;
      border-radius: .3rem;
      font-weight:600;
      font-size: 1em;
    }
    span.critical{
      background-color: #CD171A;
      color: #FCD926;
    }
    span.high{
      background-color: #FF8300;
    }
    span.medium{
      background-color: #FCD926;
    }
    span.low{
      background-color: #027961;
      color: cornsilk;
    }
  }

`