import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth'
import { Footer } from '../modules/common';

const LoginPage = () => {
  const auth = useAuth();
  const { SignInAccount } = auth;

  // ..state
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (e) => {
    setIsLoading(true);
    const data = new FormData(e.currentTarget);
    const email = data?.get("plenoil-email")?.toString() + "";
    const password = data?.get("plenoil-password")?.toString() + "";
    e.preventDefault();
    SignInAccount(email, password).then(()=> setIsLoading(false)).catch(err=> {setIsLoading(false);alert(err.toString())})
  }

  if (!auth.token)
    return (
      <>
        <section className="">
          <div className="px-4 px-md-5 text-center text-lg-start align-items-center" style={{ backgroundColor: "hsl(0, 0%, 96%)" }}>
            <div className="container">
              <div className="row gx-lg-5 align-items-center" style={{ height: '100vh' }}>
                <div className="col-lg-6 mb-lg-0 text-center">
                  <img src="/img/plenoil-logo.svg" alt="Logo de Plenoil" style={{ width: '100%', maxWidth: '15em' }} />
                  <h1 className="display-6 pt-2 fw-bold ls-tight">
                    MATENIMIENTO
                  </h1>
                </div>

                <div className="col-lg-6 mb-5 mb-lg-0">
                  <div className="card rounded-5">
                    <div className="card-body py-5 px-4 px-md-5">
                      <form className="text-center" onSubmit={handleSubmit}>

                        <div className="form-outline mb-4 row">
                          <label htmlFor="plenoil-email" className='text-uppercase col-xl-4 fw-semibold py-2 text-start'>Email</label>
                          <div className='col-xl-8'>
                            <input type="email" id="plenoil-email" name="plenoil-email" className="form-control" autoFocus required />
                          </div>
                        </div>

                        <div className="form-outline mb-4 row">
                          <label htmlFor="plenoil-password" className='text-uppercase col-xl-4 fw-semibold py-2 text-start'>Contraseña</label>
                          <div className='col-xl-8'>
                            <input type="password" id="plenoil-password" name="plenoil-password" className="form-control" placeholder="" required />
                          </div>
                        </div>

                        {/* <div className="form-check d-flex justify-content-center mb-4">
                            <input className="form-check-input me-2" type="checkbox" value="" id="form2Example33" checked />
                            <label className="form-check-label" for="form2Example33">
                              Recordar usuario
                            </label>
                          </div> */}

                        <button type={!isLoading ? "submit" : "button"} className="btn btn-primary btn-block mb-4 fw-semibold rounded-5 px-4"
                          style={{transition:.3}}
                        >
                          {isLoading?
                          <div className="spinner-border spinner-border-sm fw-normal" role="status"></div>
                          : <span>INGRESAR</span>
                          }
                        </button>

                        <div className="text-center">
                          <p className='text-secondary'><small>© <Footer.Copy /></small></p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer>
          <Footer.Copy />
          <Footer.Version />
        </Footer>
      </>
    )
  else
    return <Navigate to={"/"} />
};

export default LoginPage;