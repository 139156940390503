import { SpanBadge } from '../../../../../components';
import { IconCheck } from '../../../../../components/base-table/base-table.styles';
import { issueStore } from '../../../../../config/store';
import useAuth from '../../../../../hooks/useAuth';
import { useTools } from '../../../../../hooks';

const HeaderSection1 = ({ isLoading }) => {

  const { formatDate } = useTools();
  const { user } = useAuth();
  const { issueState } = issueStore()

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className='d-flex flox-row'>
        <strong className="text-nowrap me-2">
          CREADO POR:
        </strong>
        <SpanBadge>{issueState.ro_name || user?.name}</SpanBadge>
      </div>

      <strong>
        {issueState.station_name ? (
          <span className="mt-1 pe-2">
            <div className="spinner-grow spinner-grow-sm text-dark" hidden={!isLoading} role="status"></div>
            <IconCheck color="" className="" hidden={isLoading} />
          </span>
        ) : null}
        <span>{formatDate(issueState.created_at || new Date())}</span>
      </strong>
    </div>
  );
};

export default HeaderSection1;
