import styled from "styled-components";

export const StyledTable = styled.div`
  display: flex;
  align-items: flex-start;

  position: relative;
  // height: 100%;
  width: 100%;
  overflow: hidden;
  user-select: none;
`;

/**
 * STICKY CONTENT
 * --------------------
 */
export const Sticky = styled.span`
// position: absolute;
left: 0;
display: inline-block;
z-index: 1;
margin-top: 1.3rem;
border-end-start-radius: 2rem;


&.stickyShadow {
  // height: 50%;
  // box-shadow: 3px 1.5rem 1px #33333366;
}
`;

export const InnerSticky = styled.span`
display: grid;
grid-auto-flow: row;
grid-template-columns: fit-content;
& span:nth-child(even) {
  background-color: #f4f4f4;
}

&>span:nth-child(odd) {
  background-color: white;
}
&>span:not(:nth-child(2)), &>span:not(:last-child){
  border-left: 1px solid #c8c8c8;
}

&>span:nth-child(2){
  border-top:1px solid #c8c8c8;
  border-start-start-radius: 1.5rem;
  border-top: 1px solid #c8c8c8;
}
&>span:last-child{
  border-end-start-radius: 1.5rem;
  border-bottom: 1px solid #c8c8c8;
  border-left: 1px solid #c8c8c8;
}
`;

export const Row = styled.span`
padding: 1rem;
height: 59px;
min-width: 100%;
display: flex;
border-right: 1px solid #c8c8c8;
align-items: center;
& h6{
  font-weight: 600;
  margin-bottom: 0;
}
`;

/**
 * HEADING
 * --------------------
 */
export const RowHeading = styled.span`
  background-color : white;
  font-size: .7rem;
  font-weight: 600;
  display: block;
  width: 100%;
  height: 1.5rem;
  text-align: center;
  margin-left: -1px; // El pixel de la primera linea
  padding-top: .2rem;
  &:not(:first-child, :nth-child(2)) {
    border-left: 1px solid #c8c8c8;
  }

  &.header-merge{
    &:before{
      position: absolute;
      margin-top: -1.3rem;
      content: "APROBACIÓN";
      width: 14.31rem;
      margin-left: -5.29rem;
      border: 1px solid #c8c8c8;
      border-start-start-radius: .4rem;
      border-start-end-radius: .4rem;
    }
  }
`

/**
 * CONTENT
 * --------------------
 */
export const Content = styled.span`
  display: grid;
  overflow-x: scroll;
  // position: absolute;
  grid-auto-flow: row;
  padding-top: 1.3rem;
  // margin-left: ${p => (p.wSticky - 1)}px;
  width: calc(100% - ${p => p.wSticky}px);
  position: relative;
  &>*:nth-child(3){
    border-top: 1px solid #c8c8c8;
  }
  &>*:last-child{
    border-bottom: 1px solid #c8c8c8;
    background-color: red;
  }
`;

export const BorderContent = styled.div`
  position: absolute;
  z-index:1;
  overflow:hidden;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  `
  
  export const MaskContent = styled.span`
  width : 100%;
  `
  
  export const InnerContent = styled.span`
  display: grid;
  grid-auto-flow: column;
  // TODO: Pasar a la tabla de solicitudes
  grid-template-columns: minmax(170px, 1fr) minmax(55px, 1fr) minmax(70px, 1fr) repeat(6, 2.375rem) repeat(4, minmax(100px,1fr)) repeat(3, minmax(70px, 1fr)) minmax(150px, 1fr);

  &:nth-child(odd) {
    background-color: #f4f4f4;
  }
  &:nth-child(even) {
    background-color: white;
  }
`;

export const RowContent = styled.span`
display: flex;
align-items: center;
justify-content: center;
padding: 1rem;
height: 58.68px;
&:not(:last-child){
  border-right: 1px solid #c8c8c8;
}
`;

export const StyledScrollBar = styled.span`
  transition: .3s;
  height: 1rem;
  width: 100%;
  display: block;
  background-color: #0000001A;
  border-radius: 1rem;
  overflow: hidden;
  position: absolute; 
  height: 1rem;
`

export const ScrollIndicator = styled.span`
  height: 100%;
  background-color: #c8c8c8;
  border-radius: 1rem;
  display: block;
  position: absolute;
  left: 5rem;
`

/**
 * FOOTER TABLE
 * --------------------------------------------
 */

export const StyledFooter = styled.span`
  display: block;
  margin-left: 2rem;
  margin-top: 1rem;
  font-size : .8rem;
  vertical-align: middle;

  & span{
    display: inline-flex;
    align-items: center;
    margin-left: .5rem;
    & span{
      margin-right: .34rem;
    }
  }
`

export const IconWarning = styled.span`
  display: inline-block;
  mask-image : url('/img/icons/warning.svg');
  mask-repeat: no-repeat;
  width: .7rem;
  height: .7rem;
  background-color: #CD171A;
`

export const IconError = styled.span`
display: inline-block;
  mask-image : url('/img/icons/error-rounded.svg');
  mask-repeat: no-repeat;
  width: ${p=>(p.size || 1)*.7}rem;
  height: ${p=>(p.size || 1)*.7}rem;
  background-color: #CD171A88;
  &:hover{
    background-color: #CD171A;
  }
`

export const IconCheck = styled.span`
display: inline-block;
  mask-image : url('/img/icons/check.svg');
  mask-repeat: no-repeat;
  width: .7rem;
  height: .7rem;
  background-color: ${p=>p.color||'#3EA536'};
`

export const IconPencil = styled.span`
display: inline-block;
  mask-image : url('/img/icons/pencil.svg');
  mask-repeat: no-repeat;
  width: .7rem;
  height: .7rem;
  background-color: #CD171A;
`

export const IconClock = styled.span`
display: inline-block;
mask-image : url('/img/icons/clock.svg');
mask-repeat: no-repeat;
width: .7rem;
height: .7rem;
background-color: #774F8D;

`

export const IconProgress = styled.span`
  display: inline-block;
  mask-image : url('/img/icons/progress.svg');
  mask-repeat: no-repeat;
  width: .7rem;
  height: .7rem;
  background-color: #774F8D;
`