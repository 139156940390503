import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export const useStore = create(set => ({
  user: {},
  setUser: newUser => set(() => ({ user: newUser })),
}));

// .Store only issue to editting
export const issueStore = create(set => ({
  issueState: {},
  setIssueState: newState => set(() => ({ issueState: newState })),
}));


// .Store list of issues
const empty = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
const initialStatus = { DONE: '0', WARNING: '0', PENDING: '0', SCHEDULED: '0', INPROGRESS: '0' };
const initialPriority = { CRÍTICO: '0', URGENTE: '0', MEDIO: '0', LEVE: '0' };
export const useIssueStore = create(
  persist(
    set => ({
      issues: empty,
      status : initialStatus,
      priority : initialPriority,
      setIssues: val => set(() => ({ issues: val || empty })),
      setStatus: val => set(() => ({ status: val || empty })),
      setPriority: val => set(() => ({ priority: val || empty })),
    }),
    {
      name: 'issues_',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// .SETTINGS STORAGE
export const useGlobalSettingsStore = create(
  persist(
    set => ({
      globalSettings: {},
      setGlobalSettings: val => set(() => ({ globalSettings: val })),
    }),
    {
      name: 'settings',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);