import { Toast } from 'bootstrap';

const NotificationsComponent = (props) => {
  return (
    <>
      <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }}>
        <div id="liveToast" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
          <div className="toast-header">
            <img src="https://plenoil.gbbapp.com/favicon.svg" width={18} className="rounded me-2" alt="Plenoil" />
            <strong className="me-auto">PLENOIL MANTENIMIENTO</strong>
            <small>Ahora</small>
            <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div className="toast-body">{'No se han econtrado resultados para esta búsqueda.'}</div>
        </div>
      </div>
    </>
  );
};

// No se han econtrado resultados para esta búsqueda.

export const showAlert = (msg) => {
  const toastEl = document.getElementById('liveToast');
  const toast = new Toast(toastEl, {
    autohide: true,
    delay: 5000,
    animation: true,
  });
  toast.show();
};

export default NotificationsComponent;
