import { Link } from 'react-router-dom';
import { MenuBar } from '../../components';
import useAuth from '../../hooks/useAuth';
import { Header } from '../../modules/common';

const MenuBarModule = () => {
  const { user } = useAuth();

  return (
    <MenuBar>
      {/* <Header.Summary className="d-block" />
        <Header.Clock className="d-block" />
        <Header.Role className="d-block" />
        <hr /> */}
      <MenuBar.Title icon="/img/icons/maintenance.svg" color="#9B9E91">
        Mantenimiento
      </MenuBar.Title>
      <MenuBar.List>
        {(user.role.toUpperCase() === 'RO' || user.role.toUpperCase() === 'RM') && (
          <Link to="/nueva-solicitud">
            <button className="btn btn-primary rounded-pill">Nueva solicitud</button>
          </Link>
        )}
        <MenuBar.Item>
          <Link to="/solicitudes">Solicitudes</Link>
        </MenuBar.Item>
        <MenuBar.Item>
          <Link to="/solicitudes/aprobadas">Aprobadas</Link>
        </MenuBar.Item>
        <MenuBar.Item>
          <Link to="/solicitudes/planificadas">Planificadas</Link>
        </MenuBar.Item>
        <MenuBar.Item>
          <Link to="/solicitudes/ejecutadas">Ejecutadas</Link>
        </MenuBar.Item>
        <MenuBar.Item>
          <Link to="/solicitudes/desestimadas">Desestimadas</Link>
        </MenuBar.Item>
        <MenuBar.Item>
          <Link to="/resumen">Resumen estaciones</Link>
        </MenuBar.Item>
      </MenuBar.List>

      {(user.role.toUpperCase() === 'ADMIN' || user.role.toUpperCase() === 'DN') && (
        <>
          <MenuBar.Title icon="/img/icons/users.svg" color="#E6AA41" size={'1.5rem'}>
            Usuarios
          </MenuBar.Title>
          <MenuBar.List>
            <MenuBar.Item>
              <Link to="/zones">Zonas</Link>
            </MenuBar.Item>
            <MenuBar.Item>
              <Link to="/users">Usuarios</Link>
            </MenuBar.Item>
          </MenuBar.List>
        </>
      )}

      {user.role.toUpperCase() === 'ADMIN' && (
        <>
          <MenuBar.Title icon="/img/icons/settings.svg" color="#9B9E91" size={'1.5rem'}>
            Ajustes
          </MenuBar.Title>
          <MenuBar.List>
            <MenuBar.Item>
              <Link to="/ajustes">Valores predeterminados</Link>
            </MenuBar.Item>
          </MenuBar.List>
        </>
      )}
    </MenuBar>
  );
};

export default MenuBarModule;
