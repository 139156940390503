import SolidHeading from '../../../components/solid-heading';
import MainTemplate from '../../../templates/main/main.template';
import { useEffect } from 'react';
import { useState } from 'react';
import NewRequestTemplate from '..';
import { useParams } from 'react-router-dom';


const EditIssue = () => {

	const [isLoading, setIsLoading] = useState(false);
	const params = useParams();

	useEffect(() => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false)
		}, 500);
	}, [])

	return (<MainTemplate>
		<SolidHeading title={`Solicitud de mantenimiento`}><SolidHeading.End>Mod.26</SolidHeading.End></SolidHeading>
		<NewRequestTemplate idIssue={params.id} />
	</MainTemplate>
	)
}
export default EditIssue;