import { issueStore, useGlobalSettingsStore, useIssueStore } from '../config/store';
import { API, HEADERS } from '../constants';
import useAuth from '../hooks/useAuth';

const useFetch = () => {
  const { token } = useAuth();
  const { issueState, setIssueState } = issueStore();
  const { globalSettings, setGlobalSettings } = useGlobalSettingsStore();

  /**
   * --------------------
   * USERS
   * --------------------
   */
  async function listUsers(role) {
    const response = await fetch(`${API}/api/v1/users/role/${role || 'ADMIN'}`, {
      method: 'GET',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }

  async function getUser(id) {
    const response = await fetch(`${API}/api/v1/users/${id}`, {
      method: 'GET',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }

  async function createUser(object) {
    const response = await fetch(`${API}/api/v1/users/`, {
      method: 'POST',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
      body: JSON.stringify(object),
    });
    return { status: response.status, json: response.json() };
  }

  async function updateUser(id, object) {
    const response = await fetch(`${API}/api/v1/users/${id}`, {
      method: 'PUT',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
      body: JSON.stringify(object),
    });
    return { status: response.status, json: response.json() };
  }

  async function deleteUser(id) {
    const response = await fetch(`${API}/api/v1/users/${id}`, {
      method: 'DELETE',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }

  /**
   * --------------------
   * ZONES
   * --------------------
   */
  async function listZones() {
    const response = await fetch(`${API}/api/v1/zones`, {
      method: 'GET',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }

  async function updateZone(id, object) {
    const response = await fetch(`${API}/api/v1/zones/${id}`, {
      method: 'PUT',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
      body: JSON.stringify(object),
    });
    return { status: response.status, json: response.json() };
  }

  async function getZone(id) {
    const response = await fetch(`${API}/api/v1/zones/${id}`, {
      method: 'GET',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return response.json();
  }

  async function deleteZone(id) {
    const response = await fetch(`${API}/api/v1/zones/${id}`, {
      method: 'DELETE',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }

  async function createZone(name, budget, total_budget, stations) {
    const response = await fetch(`${API}/api/v1/zones`, {
      method: 'POST',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
      body: JSON.stringify({
        name: name,
        budget: budget,
        total_budget: total_budget,
        stations: stations,
      }),
    });
    return { status: response.status, json: response.json() };
  }

  /**
   * --------------------
   * STATIONS
   * --------------------
   */
  async function listStations() {
    const response = await fetch(`${API}/api/v1/stations`, {
      method: 'GET',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }

  async function getStationsByZone(idZone) {
    const response = await fetch(`${API}/api/v1/zones/${idZone}/stations`, {
      method: 'GET',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return response.json();
  }

  async function removeStations(idZone, stations) {
    const response = await fetch(`${API}/api/v1/zones/${idZone}/stations`, {
      method: 'PUT',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
      body: JSON.stringify({ stations: [...stations] }),
    });
    return { status: response.status, json: response.json() };
  }

  /**
   * --------------------
   * ISSUES
   * --------------------
   */
  async function listIssues({ zone, station, month, priority, status, page }) {
    let complement = '/api/v1/issues';
    if (zone) complement = complement + `?zone=${zone}`;
    if (station) complement = complement + (zone?'&':'?') + `station=${station}`;
    if (month) complement = complement + (zone || station ?'&':'?') + `month=${month}`;
    if (priority) complement = complement + (zone || station || month ?'&':'?') + `priority=${priority}`;
    if (status) complement = complement + (zone || station || month || priority ?'&':'?') + `status=${status}`;
    if (page) complement = complement + (zone || station || month || priority || status ?'&':'?') + `page=${page}`;
    const response = await fetch(`${API}${complement}`, {
      method: 'GET',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }

  async function createIssue(object) {
    const response = await fetch(`${API}/api/v1/issues/`, {
      method: 'POST',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
      body: JSON.stringify(object),
    });
    return { status: response.status, json: response.json() };
  }

  async function getIssue(id) {
    const response = await fetch(`${API}/api/v1/issues/${id}`, {
      method: 'GET',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }

  async function updateIssue(object, id) {
    const response = await fetch(`${API}/api/v1/issues/${id}`, {
      method: 'PUT',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
      body: JSON.stringify(object),
    });
    return { status: response.status, json: response.json() };
  }

  async function summaryIssue({zone, station, month}){
    let complement = '/api/v1/issues-summary';
    if (zone) complement = complement + `?zone=${zone}`;
    if (station) complement = complement + (zone?'&':'?') + `station=${station}`;
    if (month) complement = complement + (zone || station ?'&':'?') + `month=${month}`;
    const response = await fetch(`${API}${complement}`, {
      method: 'GET',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }
  /**
   * --------------------
   * LOGS
   * --------------------
   */
  async function createLog(object, idISSUE) {
    const response = await fetch(`${API}/api/v1/logs/issue/${idISSUE}`, {
      method: 'POST',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
      body: JSON.stringify(object),
    });
    return { status: response.status, json: response.json() };
  }

  async function getLogByIssueId(idISSUE) {
    const response = await fetch(`${API}/api/v1/logs/issue/${idISSUE}`, {
      method: 'GET',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }

  /**
   * --------------------
   * ALERTS
   * --------------------
   */
  async function createAlert(object, idISSUE) {
    const response = await fetch(`${API}/api/v1/alerts/issue/${idISSUE}`, {
      method: 'POST',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
      body: JSON.stringify(object),
    });
    return { status: response.status, json: response.json() };
  }

  async function getAlertByIssueId(idISSUE) {
    const response = await fetch(`${API}/api/v1/alerts/issue/${idISSUE}`, {
      method: 'GET',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }
  /**
   * --------------------
   * ORDERS
   * --------------------
   */
  async function getOrderNum() {
    const response = await fetch(`${API}/api/v1/generate-order`, {
      method: 'POST',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }

  /**
   * --------------------
   * SETTINGS
   * --------------------
   */
  async function getSettings() {
    const response = await fetch(`${API}/api/v1/settings`, {
      method: 'GET',
      headers: { ...HEADERS, Authorization: 'Bearer ' + token },
    });
    return { status: response.status, json: response.json() };
  }

  /**
   * -------------------------------------
   * FUNCTIONS
   * @param {*} idUSER
   * @returns
   */

  function USER(idUSER) {
    const storedADMIN = window.sessionStorage.getItem('admin');
    const storedDN = window.sessionStorage.getItem('dn');
    const storedRRO = window.sessionStorage.getItem('rro');
    const storedRO = window.sessionStorage.getItem('ro');
    const storedRM = window.sessionStorage.getItem('rm');
    const storedPRL = window.sessionStorage.getItem('prl');

    const lAdmin = prop => {
      const objADMIN = JSON.parse(storedADMIN) || [];
      return new Promise((resolve, reject) => {
        if (!storedADMIN || prop === 'refresh')
          listUsers('admin').then(({ status, json }) => {
            if (status === 200) json.then(res => window.sessionStorage.setItem('admin', JSON.stringify(res.data || [])));
          });
        resolve(objADMIN);
      });
    };

    const lDN = prop => {
      const objDN = JSON.parse(storedDN) || [];
      return new Promise((resolve, reject) => {
        if (!storedDN || prop === 'refresh')
          listUsers('dn').then(({ status, json }) => {
            if (status === 200) json.then(res => window.sessionStorage.setItem('dn', JSON.stringify(res.data || [])));
          });
        resolve(objDN);
      });
    };

    const lRRO = prop => {
      const objRRO = JSON.parse(storedRRO) || [];
      return new Promise((resolve, reject) => {
        if (!storedRRO || prop === 'refresh')
          listUsers('rro').then(({ status, json }) => {
            if (status === 200) json.then(res => window.sessionStorage.setItem('rro', JSON.stringify(res.data || [])));
          });
        resolve(objRRO);
      });
    };

    const lRO = prop => {
      const objRO = JSON.parse(storedRO) || [];
      return new Promise((resolve, reject) => {
        if (!storedRO || prop === 'refresh')
          listUsers('ro').then(({ status, json }) => {
            if (status === 200) json.then(res => window.sessionStorage.setItem('ro', JSON.stringify(res.data || [])));
          });
        resolve(objRO);
      });
    };

    const lRM = prop => {
      const objRM = JSON.parse(storedRM) || [];
      return new Promise((resolve, reject) => {
        if (!storedRM || prop === 'refresh')
          listUsers('rm').then(({ status, json }) => {
            if (status === 200) json.then(res => window.sessionStorage.setItem('rm', JSON.stringify(res.data || [])));
          });
        resolve(objRM);
      });
    };

    const lPRL = prop => {
      const objPRL = JSON.parse(storedPRL) || [];
      return new Promise((resolve, reject) => {
        if (!storedPRL || prop === 'refresh')
          listUsers('prl').then(({ status, json }) => {
            if (status === 200) json.then(res => window.sessionStorage.setItem('prl', JSON.stringify(res.data || [])));
          });
        resolve(objPRL);
      });
    };

    const awaitCreate = (type, object) => {
      const storedTYPE = window.sessionStorage.getItem(type);
      let objTYPE = JSON.parse(storedTYPE) || [];
      return new Promise((resolve, reject) => {
        createUser(object).then(({ status, json }) => {
          json.then(res => {
            if (status === 200 || status === 201) {
              objTYPE.push(res.data);
              window.sessionStorage.setItem(type, JSON.stringify(objTYPE));
              resolve(objTYPE);
            } else {
              reject(res.message);
            }
          });
        });
      });
    };

    const awaitInfo = () => {
      return new Promise((resolve, reject) => {
        getUser(idUSER).then(({ json, status }) => {
          json.then(res => {
            if (status === 200) {
              resolve(res);
            }
          });
        });
      });
    };

    const awaitUpdate = object => {
      return new Promise((resolve, reject) => {
        updateUser(idUSER, object).then(({ json, status }) => {
          if (status === 200) {
            json.then(updatedUser => {
              if (object.role === 'admin')
                lAdmin('refresh').then(res => {
                  window.sessionStorage.setItem(object.role, JSON.stringify(res));
                  resolve(updatedUser);
                });
              if (object.role === 'dn')
                lDN('refresh').then(res => {
                  window.sessionStorage.setItem(object.role, JSON.stringify(res));
                  resolve(updatedUser);
                });
              if (object.role === 'rro')
                lRRO('refresh').then(res => {
                  window.sessionStorage.setItem(object.role, JSON.stringify(res));
                  resolve(updatedUser);
                });
              if (object.role === 'ro')
                lRO('refresh').then(res => {
                  window.sessionStorage.setItem(object.role, JSON.stringify(res));
                  resolve(updatedUser);
                });
              if (object.role === 'rm')
                lRM('refresh').then(res => {
                  window.sessionStorage.setItem(object.role, JSON.stringify(res));
                  resolve(updatedUser);
                });
              if (object.role === 'prl')
                lPRL('refresh').then(res => {
                  window.sessionStorage.setItem(object.role, JSON.stringify(res));
                  resolve(updatedUser);
                });
            });
          }
        });
      });
    };

    const awaitDelete = (type, arr) => {
      return new Promise((resolve, reject) => {
        let i = 0;
        asyncDEL(arr).then(() => {
          if (type === 'admin')
            lAdmin('refresh').then(res => {
              window.sessionStorage.setItem(type, JSON.stringify(res));
              resolve('success');
            });
          if (type === 'dn')
            lDN('refresh').then(res => {
              window.sessionStorage.setItem(type, JSON.stringify(res));
              resolve('success');
            });
          if (type === 'rro')
            lRRO('refresh').then(res => {
              window.sessionStorage.setItem(type, JSON.stringify(res));
              resolve('success');
            });
          if (type === 'ro')
            lRO('refresh').then(res => {
              window.sessionStorage.setItem(type, JSON.stringify(res));
              resolve('success');
            });
          if (type === 'rm')
            lRM('refresh').then(res => {
              window.sessionStorage.setItem(type, JSON.stringify(res));
              resolve('success');
            });
          if (type === 'prl')
            lPRL('refresh').then(res => {
              window.sessionStorage.setItem(type, JSON.stringify(res));
              resolve('success');
            });
        });
      });
    };

    async function asyncDEL(arr) {
      await arr.forEach(element => {
        deleteUser(element.id);
      });
    }

    return {
      lAdmin,
      lDN,
      lRRO,
      lRO,
      lRM,
      lPRL,
      awaitCreate,
      awaitInfo,
      awaitUpdate,
      awaitDelete,
    };
  }

  function STATION(idSTATION) {
    const storedSTATIONS = window.sessionStorage.getItem('stations');
    // const objSTATIONS = JSON.parse(storedSTATIONS) || []
    const lStations = prop => {
      const objSTATIONS = JSON.parse(storedSTATIONS) || [];
      return new Promise((resolve, reject) => {
        if (!storedSTATIONS || prop === 'refresh') {
          listStations().then(({ status, json }) => {
            if (status === 200)
              json.then(res => {
                window.sessionStorage.setItem('stations', JSON.stringify(res.data || []));
                resolve(res.data);
              });
          });
        } else {
          resolve(objSTATIONS);
        }
      });
    };
    return {
      lStations,
    };
  }

  function ZONE(idZONE) {
    const storedZONE = window.sessionStorage.getItem(idZONE);
    const objZONE = JSON.parse(storedZONE) || [];
    // ..
    const storedZONES = window.sessionStorage.getItem('zones');
    const objZONES = JSON.parse(storedZONES) || [];

    const lZones = prop => {
      const objZONES = JSON.parse(storedZONES) || [];
      return new Promise((resolve, reject) => {
        if (!storedZONES || prop === 'refresh') {
          listZones().then(({ status, json }) => {
            if (status === 200)
              json.then(res => {
                window.sessionStorage.setItem('zones', JSON.stringify(res.data || []));
                resolve(res.data);
              });
          });
        } else {
          resolve(objZONES);
        }
      });
    };

    const awaitCreate = (name, budget, total_budget, stations) => {
      return new Promise((resolve, reject) => {
        createZone(name, parseFloat(budget), parseFloat(total_budget), stations).then(({ status, json }) => {
          if (status === 200 || status === 201) {
            json.then(res => {
              objZONES.push(res.data);
              window.sessionStorage.setItem('zones', JSON.stringify(objZONES));
              resolve(objZONES);
            });
          } else {
            resolve(objZONES);
          }
        });
      });
    };

    const awaitInfo = () => {
      return new Promise((resolve, reject) => {
        if (!objZONE?.budget) {
          getZone(idZONE).then(res => {
            let object = {};
            object = JSON.stringify({ ...objZONE, ...res.data } || { budget: 0.2 });
            window.sessionStorage.setItem(idZONE, object);
            resolve(object);
          });
        } else {
          resolve(JSON.stringify(objZONE));
        }
      });
    };

    const awaitStations = () => {
      return new Promise((resolve, reject) => {
        if (!objZONE.stations) {
          getStationsByZone(idZONE).then(res => {
            let object = JSON.stringify({ ...objZONE, stations: res.data } || []);
            window.sessionStorage.setItem(idZONE, object);
            if (res.data) {
              resolve(res.data);
            }
          });
        } else {
          resolve(objZONE.stations);
        }
      });
    };

    const awaitUpdate = object => {
      return new Promise((resolve, reject) => {
        updateZone(idZONE, object).then(({ json, status }) => {
          if (status === 200) {
            json.then(res => {
              window.sessionStorage.setItem(idZONE, JSON.stringify(res.data));
              lZones('refresh').then(() => resolve(res.data));
            });
          }
        });
      });
    };

    const awaitDelete = () => {
      return new Promise((resolve, reject) => {
        deleteZone(idZONE).then(({ status, json }) => {
          if (status === 200 || status === 201) {
            let element = objZONES.find(item => item.id === idZONE);
            element = objZONES.indexOf(element);
            objZONES.splice(element, 1);
            window.sessionStorage.setItem('zones', JSON.stringify(objZONES));
            window.sessionStorage.removeItem(idZONE);
            resolve(objZONES);
          } else {
            resolve(objZONES);
          }
        });
      });
    };

    return {
      lZones,
      awaitCreate,
      awaitInfo,
      awaitStations,
      awaitUpdate,
      awaitDelete,
    };
  }

  const {issues, setIssues, setStatus, setPriority} = useIssueStore();
  function ISSUE(idISSUE) {
    const storedISSUE = window.sessionStorage.getItem(idISSUE);
    const storedISSUES = window.sessionStorage.getItem('issues');
    const objISSUE = JSON.parse(storedISSUE);

    const lIssues = prop => {
      const objISSUES = JSON.parse(storedISSUES) || [];
      return new Promise((resolve, reject) => {
        if (!storedISSUES || prop.action === 'refresh' || !issues) {
          listIssues({ zone: prop?.zone, station: prop?.station, month: prop?.month, priority: prop?.priority, status: prop?.status, page: prop?.page }).then(({ status, json }) => {
            if (status === 200)
              json.then(res => {
                // window.sessionStorage.setItem('issues', JSON.stringify(res.data || []));
                setIssues(res.data || null);
                setStatus(res.status || null);
                setPriority(res.priority || null);
                resolve(res);
              });
          });
        } else {
          // setIssues(objISSUES)
          resolve(issues);
        }
      });
    };
    // const lZones = (prop) => {
    //     const objZONES = JSON.parse(storedZONES) || []
    //     return new Promise((resolve, reject)=>{
    //         if(!storedZONES || prop === 'refresh') {
    //             listZones().then(({ status, json }) => {
    //                 if (status === 200) json.then(res => { window.sessionStorage.setItem('zones', JSON.stringify(res.data || []));resolve(res.data); });})
    //             }
    //         else{
    //             resolve(objZONES);
    //         }
    //     })
    // }

    const awaitCreate = object => {
      return new Promise((resolve, reject) => {
        createIssue(object).then(({ status, json }) => {
          json.then(res => {
            if (status === 200 || status === 201) {
              // objZONES.push(res.data)
              // window.sessionStorage.setItem('zones',JSON.stringify(objZONES))
              // resolve(objZONES)
              resolve(res.data);
            } else {
              reject(res.message);
            }
          });
        });
      });
    };

    const awaitInfo = () => {
      return new Promise((resolve, reject) => {
        getIssue(idISSUE).then(({ json }) => {
          if (!objISSUE?.id) {
            let object = {};
            json.then(res => {
              object = JSON.stringify({ ...res.data });
              window.localStorage.setItem('idataSaved', window.btoa(JSON.stringify(object)));
              resolve(object);
            });
            // window.sessionStorage.setItem(idISSUE,window.btoa(JSON.stringify(object)));
            // TODO: GUARDAR RESPUESTA EN ZUSTAND
            // setIssueState(object)
          } else {
            resolve(JSON.stringify(objISSUE));
          }
        });
      });
    };

    const awaitUpdate = object => {
      return new Promise((resolve, reject) => {
        updateIssue(object, idISSUE).then(({ status, json }) => {
          json.then(res => {
            if (status === 200 || status === 201) {
              // objZONES.push(res.data)
              // window.sessionStorage.setItem('zones',JSON.stringify(objZONES))
              // resolve(objZONES)
              resolve(res.data);
            } else {
              reject(res?.message);
            }
          });
        });
      });
    };

    const awaitSummary = object => {
      return new Promise((resolve, reject)=>{
        summaryIssue(object).then(({status, json})=>{
          json.then(res => {
            if(status === 200){
              resolve(res)
            }else{
              reject(res?.message)
            }
          })
        })
      })
    }

    return {
      lIssues,
      awaitCreate,
      awaitInfo,
      awaitUpdate,
      awaitSummary,
    };
  }

  function LOG(idLOG) {
    const awaitCreate = (object, idISSUE) => {
      return new Promise((resolve, reject) => {
        createLog(object, idISSUE).then(({ status, json }) => {
          json.then(res => {
            if (status === 200 || status === 201) {
              resolve(res);
            } else {
              reject(res.message);
            }
          });
        });
      });
    };
    const byIssue = idISSUE => {
      return new Promise((resolve, reject) => {
        getLogByIssueId(idISSUE).then(({ status, json }) => {
          json.then(res => {
            if (status === 200 || status === 201) {
              resolve(res.data);
            } else {
              reject(res.message);
            }
          });
        });
      });
    };

    return { awaitCreate, byIssue };
  }

  function ALERT(idISSUE) {
    const awaitCreate = (object, idISSUE) => {
      return new Promise((resolve, reject) => {
        createAlert(object, idISSUE).then(({ status, json }) => {
          json.then(res => {
            if (status === 200 || status === 201) {
              resolve(res);
            } else {
              reject(res.message);
            }
          });
        });
      });
    };
    const byIssue = idISSUE => {
      return new Promise((resolve, reject) => {
        getAlertByIssueId(idISSUE).then(({ status, json }) => {
          json.then(res => {
            if (status === 200 || status === 201) {
              resolve(res.data);
            } else {
              reject(res.message);
            }
          });
        });
      });
    };

    return { awaitCreate, byIssue };
  }

  function ORDER() {
    const generateNumber = () => {
      return new Promise((resolve, reject) => {
        getOrderNum().then(({ status, json }) => {
          json.then(res => {
            if (status === 200) {
              resolve(res.orderNumber);
            } else {
              reject(res.message);
            }
          });
        });
      });
    };
    return { generateNumber };
  }

  function SETTINGS() {
    // ..using globalSettingsStorage
    const lSettings = prop => {
      return new Promise((resolve, reject) => {
        getSettings().then(({status, json})=> json.then(res=> {
          let parsedSettings = {...globalSettings, ...res.data[0]}
          setGlobalSettings(parsedSettings);
        }))
      });
    };
    return { lSettings };
  }

  return {
    USER,
    createUser,
    listUsers,
    getUser,
    updateUser,
    deleteUser,
    listZones,
    listStations,
    createZone,
    getStationsByZone,
    removeStations,
    STATION,
    ZONE,
    getZone,
    updateZone,
    deleteZone,
    ISSUE,
    LOG,
    ALERT,
    ORDER,
    SETTINGS,
  };
};

export default useFetch;
