const SettingsSection = ({isLoading}) => {
  return (
    <form action="">
      <div className="mb-3 row align-items-center">
        <label htmlFor="#" className="col-2 py-0 col-form-label text-secondary fw-semibold text-uppercase">
          Director nacional
        </label>
        <div className="col-3 d-flex align-items-end ps-sm-0 py-1">
          <input type="text" className="form-control py-0" defaultValue="7200" disabled/>
        </div>
      </div>
      {/* <button type={'button'} className="btn btn-primary float-end py-0 px-5 mt-3 fw-semibold rounded-5 text-uppercase">
        <div className="spinner-border spinner-border-sm fw-normal" role="status"></div>
        Guardar
      </button> */}
    </form>
  );
};

export default SettingsSection;
