import { useEffect } from 'react';
import { useIssueStore } from '../../config/store';
import { StyledOutlineHeading, SpanTitle, StyledHorizontalList, StyledItem, SpanNumber, StyledLastItems } from './outline-heading.styles';

const OutlineHeading = ({ children, title, ...rest }) => {
  const { issues, status, priority } = useIssueStore()

  return (
    <StyledOutlineHeading className='d-xxl-flex px-2 px-lg-0' {...rest}>
        <SpanTitle className='pe-5'>{title}</SpanTitle>
        <div className='d-flex flex-wrap justify-content-between align-items-end ps-xxl-3 mt-lg-2' style={{flex:1}}>
          <StyledHorizontalList className='ps-0'>
            <StyledItem className='ps-2 pb-21 pt-2'><span className='text-secondary fw-semibold'>Activas</span> <SpanNumber className='text-primary px-2'>{issues.ACTIVE || 0}</SpanNumber></StyledItem>
            <StyledItem className='ps-2 pb-21 pt-2'><span className='text-secondary fw-semibold'>En ejecución</span> <SpanNumber className='text-primary px-2'>{status.INPROGRESS || 0}</SpanNumber></StyledItem>
            <StyledItem className='ps-2 pb-21 pt-2'><span className='text-secondary fw-semibold'>Planificadas</span> <SpanNumber className='text-primary px-2'>{status.SCHEDULED || 0}</SpanNumber></StyledItem>
            <StyledItem className='ps-2 pb-21 pt-2'><span className='text-secondary fw-semibold'>Cerradas</span> <SpanNumber className='text-primary px-2'>{status.DONE || 0}</SpanNumber></StyledItem>
          </StyledHorizontalList>
          <StyledLastItems className='d-flex flex-wrap ps-0 mb-2'>
            <li><span className='critical'>{priority.CRÍTICO}</span> <small className='label'>critico</small></li>
            <li><span className='high'>{priority.URGENTE}</span> <small className='label'>urgente</small></li>
            <li><span className='medium'>{priority.MEDIO}</span> <small className='label'>medio</small></li>
            <li><span className='low'>{priority.LEVE}</span> <small className='label'>leve</small></li>
          </StyledLastItems>
        </div>
    </StyledOutlineHeading>
  );
};

export default OutlineHeading;
