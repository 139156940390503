export default function BaseModal({ id, children }) {
  const ID = id || '';

  return (
    <div className="modal fade" id={ID} tabIndex="-1" aria-labelledby={ID} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
}
BaseModal.Title = function BaseModalTitle({ children, ...rest }) {
  return (
    <div className="modal-header pb-1" {...rest}>
      <h5 className="modal-title text-uppercase" id={'modalTitle'}>
        {children}
      </h5>
      <button type="button" className="btn-close mb-0" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
  );
};

BaseModal.Body = function BaseModalBody({ children, ...rest }) {
  return (
    <div className="modal-body" {...rest}>
      {children}
    </div>
  );
};

BaseModal.Row = ({ children, ...rest }) => (
  <div className="row align-items-center" {...rest}>
    {children}
  </div>
);
BaseModal.Label = ({ children, ...rest }) => (
  <label {...rest} className="col-sm-3 col-form-label pe-2 py-xs-0 text-sm-end text-uppercase" style={{ fontSize: '.86em' }}>
    {children}:
  </label>
);
BaseModal.Input = ({ children, preloading, ...rest }) => (
  <div className="col-sm-9 ps-sm-0 py-1 placeholder-glow">
    <input type="text" className={`form-control py-0 ${preloading && 'placeholder'}`} {...rest} />
    {children}
  </div>
);
BaseModal.Textarea = ({ children, preloading, ...rest }) => (
  <div className="col-sm-9 ps-sm-0 py-1 placeholder-glow">
    <textarea className={`form-control ${preloading && 'placeholder'}`} {...rest}>
      {children}
    </textarea>
  </div>
);

// export default BaseModal;
