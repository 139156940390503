import styled from "styled-components";

export const StyledHeading = styled.div`
  background-color: #77508D;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const SpanTitle = styled.span`
  color: white;
  padding-right: 2rem;
  font-weight: 600;
  font-size: 1.65rem;
  text-transform: uppercase;
`

export const SpanNavigation = styled.span`
  display: flex;
  color: white;
  font-size:1.65rem;
  font-weight: 600;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledSpan = styled.span`
  font-weight: 600;
  font-size: .86rem;
`

export const StyledInputContainer = styled.span`
  background-color: white;
  position: relative;
  padding: .05rem 0;
  font-size: .85rem;
  border-radius: .5rem;
  margin-left: .5rem;
  margin-right: 1rem;
  overflow:hidden;
  
  & select, & input{
    background-color: transparent;
    padding-left: .85rem;
    padding-right:${p=> p.selector ? '2rem' : '.5rem'};
    font-weight: 600;
    appearance: none;
    border: none;
    outline: none;
    width: 100%;
  }

  &[disabled]{
    select{
      color: black;
      cursor: default;
    }
  }

  ${
    p => p.selector ? `&:not([disabled]){
      &:before{
        content: "";
        mask-image : url("/img/icons/arrow-down.svg");
        transform: rotate(135deg);
        background-color: #575756;
        mask-repeat: no-repeat;
        width: .55rem;
        height: .55rem;
        position: absolute;
        right: .66rem;
        bottom: .66em;
        pointer-events: none;
      }
    }`:null
  }
`

export const StyledEndHead = styled.div`
  text-align: right;
`