import React from 'react';
const IssuesList = React.lazy(() => import('../../components/issues-list'));

const Page12Module = () => {
  return (
    <React.Suspense>
      <IssuesList />
    </React.Suspense>
  );
};

export default Page12Module;
