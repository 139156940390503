import { useEffect, useState } from 'react';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import {
  StyledLink,
  StyledSubheader,
  SpanCheckboxContainer,
  StyledContainerTable
} from './styles';
import { useTools } from '../../../hooks';
import { SmallButton } from '../../../components';
import Section1Page21Modal from './modalZone';
import useFetch from '../../../hooks/useFetch';
import { IconError } from '../../../components/base-table/base-table.styles';
import ModalNotification from '../../../components/modal-notification';

export const Section1Page21 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const loader = useLoaderData();
  const { STATIONS } = loader;
  const { ZONE, removeStations } = useFetch()
  const { formatDate, formatEUR } = useTools();
  const [checkedStations, setCheckedStations] = useState([]);
  const [ARR_STATIONS, setARR_STATIONS] = useState([]);
  const [zoneData, setZoneData] = useState({});
  const [budget, setBudget] = useState(0)
  const [total_budget, setTotal_budget] = useState(0)
  const [hashZONE, setHashZONE] = useState(location.hash?.split('#')[1] || '');
  
  const storedZONES = window.sessionStorage.getItem('zones');
  const objZONES = JSON.parse(storedZONES) || [];
  
  useEffect(() => {
    setHashZONE(location.hash?.split('#')[1] || '')
    if(!location.hash) navigate('/zones#'+ (objZONES[0]?.id || ''));
    loadInfo(location.hash.split("#")[1]);
    }, [location.hash]);

  const loadInfo = (id) => {
    const { awaitInfo } = ZONE(id);
    if(id){
      awaitInfo().then(res => {
        let objRes = JSON.parse(res)
        setZoneData(objRes)
        setBudget(objRes.budget)
        setTotal_budget(objRes.total_budget)
        loadStations(location.hash.split("#")[1]);
      }).catch(err=>console.error("CATCH::",err))
    }
  }

  const loadStations = (id) => {
    const { awaitStations } = ZONE(id);
    awaitStations().then(res=>setARR_STATIONS(res));
  }

  const handleCheck = (id, value) => {
    let a = []
    a = [...ARR_STATIONS]
    a[id] = {...a[id], checked : value}
    setARR_STATIONS(a)
  }

  const handleDeleteRecord = () => {
    let a = []
    a = ARR_STATIONS
    const newARR = a.filter(i=> !i.checked)
    const checked = a.filter(i=> i.checked);
    removeStations(hashZONE,checked).then(({json,status})=>{
      json.then(res=>{
        console.log(res)
      })
    })
    setCheckedStations(checked)
    document.getElementById("checkUser").checked = false;
    setARR_STATIONS([...newARR])
  }


  return (
    <>
      <Section1Page21.Subheader location={location} zones={objZONES} />
      <div className='pb-4 ps-2'>
       

        <div>
          <span>ASIGNACIÓN POR ESTACIÓN</span>
          <span className='fw-semibold fs-3 ps-2'>
            {formatEUR((budget||0))}
        </span>
        </div>
        <div>
          <span>TOTAL ZONA</span>
          <span className='fw-semibold fs-3 ps-2'>
            {formatEUR((total_budget||0))}
          </span>
        </div>
      </div>
      <div className="d-flex flex-wrap align-items-start">
        <StyledContainerTable>
          <table className="table fw-semibold" style={{fontSize:'1em'}}>
            <tbody>
              {ARR_STATIONS?.map((v, i) => (
                <tr key={'tr-' + i}>
                  <td>
                    <Section1Page21.CheckboxName
                      name={v.name}
                      id={(i < 9 ? '0' : null) + (i + 1)}
                      onChecked={(e)=>handleCheck(i,e)}
                      checked={v.checked}
                    />
                  </td>
                  <td>{v.email}</td>
                  <td>{formatDate(v.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </StyledContainerTable>
        <div className='mx-sm-4'>
          {/* <SmallButton
            icon="+"
            // data-bs-toggle="modal"
            // data-bs-target="#zoneModal"
          >
            AÑADIR
          </SmallButton> */}
          {/* <SmallButton icon="-" data-bs-toggle="modal" data-bs-target="#modalNotification" className={"px-3"}>ELIMINAR</SmallButton> */}
          <SmallButton
            data-bs-toggle="modal"
            data-bs-target="#modalZone"
            data-modal-type={hashZONE}
            href="#"
            >EDITAR ZONA</SmallButton>
          <ModalNotification
            title={"AVISO"}
            description={`¿Está seguro de que desea eliminar ${checkedStations.length === 1 ? 'la estación seleccionada' : 'las estaciones seleccionadas'} de la zona?`} 
            btnSend={"Eliminar"} 
            onClickSend={(e)=>{handleDeleteRecord()}}/>
        </div>
      </div>
      <Section1Page21Modal zone={hashZONE} zoneData={zoneData} stations={ARR_STATIONS} />
    </>
  );
};

Section1Page21.Subheader = function Section1Page21Subheader({ location, zones }) {
  const { ZONE } = useFetch()
  const [arrZones, setArrZones] = useState([])
  const navigate = useNavigate();
  const [activeZONE, setactiveZONE] = useState('');
  
  useEffect(()=>{
    setArrZones(zones)
  },[zones])


  // - effect de zona seleccionada
  useEffect(()=>{
    setactiveZONE(location.hash)
  },[location])


  const resolveName = () => { const user = arrZones.find(({id}) => id === activeZONE.split('#')[1] ); return user?.name?.toUpperCase() }

  return (
    <StyledSubheader className='mb-4'>
      {arrZones.map((v, i) => {
        return(<StyledLink
        to={`#${v.id}`}
        key={'{StyledSubheader.LINK}' + i}
        className={`${activeZONE === "#"+v.id && 'active'}`}
        >
          <span className='text-uppercase'>
            {v.name}
          </span>
          <span className='ps-22 py-2' data-bs-toggle="modal" data-bs-target="#deleteZone" 
            // onClick={()=> ZONE(v.id).awaitDelete().then(()=>{navigate('/zones')}) }
          >
            <span style={{width: '2rem'}}>
              { activeZONE === "#" + v.id && <IconError size={1.12} /> }
            </span>
          </span>
        </StyledLink>)
        })}
      <StyledLink
        className='newZone'
        data-bs-toggle="modal"
        data-bs-target="#modalZone"
        data-modal-type={""}
      >
        AÑADIR ZONA
      </StyledLink>

      <ModalNotification
        id={"deleteZone"}
        title={"AVISO"}
        description={`¿Está seguro de que desea eliminar ${resolveName()}?`}
        btnSend={"Eliminar"} 
        onClickSend={()=> ZONE(activeZONE.split('#')[1]).awaitDelete().then(()=>{navigate('/zones');navigate(0)})}
      />
    </StyledSubheader>
  );
};

Section1Page21.CheckboxName = function Section1Page21CheckboxName({
  name,
  id,
  onChecked,
  checked = false
}) {
  return (
    <SpanCheckboxContainer>
      <input
        className="form-check-input rounded-5"
        type="checkbox"
        checked={checked}
        onChange={(e)=>{onChecked(e.target.checked)}}
        id={"checkUser"}
        name={"checkUser"}
      />
      <span>
      {id} - {name}
      </span>
    </SpanCheckboxContainer>
  );
};



export default Section1Page21;