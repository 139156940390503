import { useEffect, useState } from 'react';
import { useAuth, useTools, useFetch } from '../../../../hooks';
import { StyledBorderArticle, StyledContainerValue, StyledFirstPart, StyledRowInput } from '../../styles';
import { issueStore } from '../../../../config/store';
import { Select } from '../../../../components';
import { OkCancelGroup } from '../../utils/okcancel-group';

const dict_SI_NO = { true: 'SI', false: 'NO' };
const STATIC_ORDER = '000 - 0000000';
const ROLE_TO_EDIT = ['ro', 'rm'];

export const RowInput = ({ children, ...rest }) => {
  return (
    <StyledRowInput
      {...rest}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </StyledRowInput>
  );
};

const LastRowInfo = ({ children, ...rest }) => {
  return (
    <div {...rest} style={{ height: '1.6rem', display: 'block', borderTop: '1px solid white', paddingTop: '.2rem', paddingLeft: '1rem', width: '100%' }}>
      {children}
    </div>
  );
};

const ContainerValue = ({ children }) => {
  return (
    <StyledContainerValue
      style={{
        display: 'inline-block',
        paddingTop: '.3em',
        paddingLeft: '1.2em',
        paddingRight: '1.2em',
        width: 'fit-content',
        textAlign: 'center',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: '.6rem',
        marginBottom: '.5rem',
      }}
    >
      {children}
    </StyledContainerValue>
  );
};

export const Article1 = ({ userRole, isLoading, autoSave, forceSave }) => {
  // ..HOOKS
  const { ZONE, STATION, USER, ORDER } = useFetch();
  const { formatEUR } = useTools();
  const { user } = useAuth();

  const [loading, setLoading] = useState(isLoading);
  const [isDisabled, setIsDisabled] = useState(true);
  // ..options
  const [arrSTATIONS, setarrSTATIONS] = useState([]);
  const [arrTYPE, setarrTYPE] = useState([]);
  const [arrPRIORITY, setarrPRIORITY] = useState([]);
  const [arrDAY, setarrDAY] = useState([]);
  const [arrWARRANTY, setarrWARRANTY] = useState([]);
  const [arrSUPPLIERS, setarrSUPPLIERS] = useState([]);
  const [arrISSUE_LEVEL, setarrISSUE_LEVEL] = useState([]);
  const [isRM, setisRM] = useState(false);

  // ..store
  const { issueState, setIssueState } = issueStore();

  // ..tools pasar utils
  const { formatDate } = useTools();

  useEffect(() => {
    if (userRole.toLowerCase() === 'rm') setisRM(true);
  }, [userRole]);

  useEffect(() => {
    setLoading(true);
    ZONE(user?.zone?.id)
      .awaitStations()
      .then(res => {
        let filterStations = [];
        if (res.length === 0) {
          STATION()
            .lStations()
            .then(stations => setarrSTATIONS(stations));
        } else {
          res.forEach(item => filterStations.push({ name: item.name, id: item.id }));
          setarrSTATIONS(filterStations);
        }
        USER(user?.id)
          .awaitInfo()
          .then(userData => {
            setLoading(false);
            if (userData.stations?.length > 0) {
              userData?.stations?.forEach(item => filterStations.push({ name: item.name + ' 🚩', id: item.id }));
              setarrSTATIONS([...arrSTATIONS, ...filterStations]);
            }
          });
      });
    setIssueState({});
  }, [user]);

  // ..STATIC OPTIONS
  useEffect(() => {
    setarrTYPE([
      { id: '1', name: '1' },
      { id: '2', name: '2' },
    ]);
    setarrPRIORITY([
      { id: '1', name: 'LEVE' },
      { id: '2', name: 'MEDIO' },
      { id: '3', name: 'URGENTE' },
      { id: '4', name: 'CRÍTICO' },
    ]);
    setarrDAY([
      { id: '1', name: 'DIURNO' },
      { id: '2', name: 'NOCTURNO' },
    ]);
    setarrWARRANTY([
      { id: '1', name: true },
      { id: '2', name: false },
    ]);
    setarrISSUE_LEVEL([{ id: '1', name: 'OPTION' }]);
    setarrSUPPLIERS([
      { id: '1', name: 'Proveedor #1' },
      { id: '2', name: 'Proveedor #2' },
      { id: 'n', name: 'Proveedor ...n' },
    ]);
  }, []);

  /**
   * Valida el role para
   * activar o desactivar campos de entrada
   */
  useEffect(() => {
    let res = ROLE_TO_EDIT.some(element => element === user.role.toLowerCase());
    let userID = String(user.id).toLowerCase();
    let editorIDs = [];
    if (issueState.creator_id) {
      editorIDs.push(String(issueState.creator_id), String(issueState.ro_id));
      res = res && editorIDs.some(_ => _ === userID);
    }
    setIsDisabled(!res);
  }, [issueState]);

  const generateOrder = (e, parsed) => {
    if (!parsed.order_id) {
      ORDER()
        .generateNumber()
        .then(res => {
          let parsedState = { ...parsed, order_id: res };
          setIssueState(parsedState);
          forceSave(e, parsedState, 'APROBADO');
        });
    } else {
      forceSave(e, parsed, 'APROBADO');
    }
  };

  return (
    <StyledFirstPart
      style={{
        display: 'flex',
        padding: '.51rem 0',
        marginTop: '.2rem',
        justifyContent: 'space-around',
        gap: '1rem',
      }}
      className={'flex-wrap flex-md-nowrap placeholder-glow'}
    >
      <article
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '.1rem',
        }}
        className={'w-100'}
      >
        <RowInput>
          <label htmlFor="selectStation" className="w-25">
            us
          </label>
          <span className="placeholder-wave w-75">
            {loading ? (
              <span className={'w-100 mb-1 placeholder placeholder-lg rounded-1'}></span>
            ) : (
              <Select
                id="selectStation"
                options={arrSTATIONS}
                disabled={isDisabled}
                value={issueState.station_name || ' '}
                onValue={({ id, name }) => {
                  let parsedState = { ...issueState, us: name, station_id: id };
                  setIssueState(parsedState);
                }}
              />
            )}
          </span>
        </RowInput>

        <RowInput>
          <label htmlFor="selectType" className="w-25">
            tipo
          </label>
          <span className="placeholder-wave w-75">
            {loading ? (
              <span className={'w-100 placeholder mb-1 placeholder-lg rounded-1'}></span>
            ) : (
              <Select
                id="selectType"
                options={arrTYPE}
                disabled={isDisabled}
                value={issueState.issue_type || ' '}
                onBlur={autoSave}
                onChange={e => {
                  let parsedState = { ...issueState, issue_type: e.target.value };
                  setIssueState(parsedState);
                  setTimeout(() => e.target.blur(), 124);
                }}
              />
            )}
          </span>
        </RowInput>

        <RowInput>
          <label htmlFor="selectPriority" className="w-25">
            urgencia
          </label>
          <span className="placeholder-wave w-75">
            {loading ? (
              <span className={'w-100 placeholder mb-1 placeholder-lg rounded-1'}></span>
            ) : (
              <Select
                id="selectPriority"
                options={arrPRIORITY}
                disabled={isDisabled}
                value={issueState.priority || ' '}
                onBlur={autoSave}
                onChange={e => {
                  let parsedState = { ...issueState, priority: e.target.value };
                  setIssueState(parsedState);
                  setTimeout(() => e.target.blur(), 124);
                }}
              />
            )}
          </span>
        </RowInput>

        <RowInput>
          <label htmlFor="" className="w-25">
            horario
          </label>
          <span className="placeholder-wave w-75">
            {loading ? (
              <span className={'w-100 placeholder mb-1 placeholder-lg rounded-1'}></span>
            ) : (
              <Select
                id="selectDay"
                options={arrDAY}
                disabled={isDisabled}
                value={issueState.day || ' '}
                onBlur={autoSave}
                onChange={e => {
                  let parsedState = { ...issueState, day: e.target.value };
                  setIssueState(parsedState);
                  setTimeout(() => e.target.blur(), 124);
                }}
              />
            )}
          </span>
        </RowInput>

        <RowInput>
          <label htmlFor="" className="w-25">
            garantía
          </label>
          <span className="placeholder-wave w-75">
            {loading ? (
              <span className={'w-100 placeholder mb-1 placeholder-lg rounded-1'}></span>
            ) : (
              <Select
                id="selectWarranty"
                dict={dict_SI_NO}
                options={arrWARRANTY}
                disabled={isDisabled}
                value={issueState.warranty === 0 ? 'NO' : issueState.warranty === 1 ? 'SI' : ' '}
                onBlur={autoSave}
                onChange={e => {
                  let parsedState = { ...issueState, warranty: e.target.value === 'SI' ? true : false };
                  setIssueState(parsedState);
                  setTimeout(() => e.target.blur(), 124);
                }}
              />
            )}
          </span>
        </RowInput>

        <RowInput>
          <label htmlFor="" className="w-25 w-sm-50">
            garantía
          </label>
          <span className="placeholder-wave w-75">
            {loading ? (
              <span className={'w-100 placeholder mb-1 placeholder-lg rounded-1'}></span>
            ) : (
              <Select
                id="selectWarranty2"
                dict={dict_SI_NO}
                options={arrWARRANTY}
                disabled={isDisabled}
                value={issueState.warranty2 === 0 ? 'NO' : issueState.warranty2 === 1 ? 'SI' : ' '}
                onBlur={autoSave}
                onChange={e => {
                  let parsedState = { ...issueState, warranty2: e.target.value === 'SI' ? true : false };
                  setIssueState(parsedState);
                  setTimeout(() => e.target.blur(), 124);
                }}
              />
            )}
          </span>
        </RowInput>

        <RowInput>
          <label htmlFor="" className="w-50">
            tipo de siniestro
          </label>
          <span className="placeholder-wave w-50">
            {loading ? (
              <span className={'w-100 placeholder mb-1 placeholder-lg rounded-1'}></span>
            ) : (
              <Select
                id="selectIssueLevel"
                options={arrISSUE_LEVEL}
                disabled={isDisabled}
                value={issueState.issue_level || ' '}
                onBlur={autoSave}
                onChange={e => {
                  let parsedState = { ...issueState, issue_level: e.target.value };
                  setIssueState(parsedState);
                  setTimeout(() => e.target.blur(), 124);
                }}
              />
            )}
          </span>
        </RowInput>

        <RowInput>
          <label htmlFor="" className="w-50">
            imputación
          </label>
          <span className="placeholder-wave w-50">
            {loading ? (
              <span className={'w-100 placeholder mb-1 placeholder-lg rounded-1'}></span>
            ) : (
              <Select
                id="selectCharge"
                options={[{ id: '' }, { id: '', name: 'OPTION' }]}
                disabled={isDisabled}
                value={issueState.charge || ' '}
                onBlur={autoSave}
                onChange={e => {
                  let parsedState = { ...issueState, charge: e.target.value };
                  setIssueState(parsedState);
                  setTimeout(() => e.target.blur(), 124);
                }}
              />
            )}
          </span>
        </RowInput>

        <RowInput>
          <label htmlFor="" className="w-50">
            proveedor
          </label>
          <span className="placeholder-wave w-50">
            {loading ? (
              <span className={'w-100 placeholder mb-1 placeholder-lg rounded-1'}></span>
            ) : (
              <Select
                id="selectSupplier"
                options={arrSUPPLIERS}
                disabled={isDisabled}
                value={issueState.supplier || ' '}
                onBlur={autoSave}
                onChange={e => {
                  let parsedState = { ...issueState, supplier: e.target.value };
                  setIssueState(parsedState);
                  setTimeout(() => e.target.blur(), 124);
                }}
              />
            )}
          </span>
        </RowInput>
      </article>

      <StyledBorderArticle className="d-flex flex-column p-2 w-100">
        <h1 style={{ fontSize: '2em', width: 'calc(100% - 1rem)', padding: '.1rem .3rem', borderRadius: '.3rem', marginTop: '.5rem', background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'nowrap' }} className={'row w-100 mx-0 placeholder-wave'}>
          <strong style={{ fontSize: '.47em' }} className="col-12 col-md-3 text-md-end text-nowrap">
            PEDIDO Nº
          </strong>{' '}
          <span className={`col-12 col-md-9 text-md-center text-nowrap ${loading && 'placeholder rounded-2 ms-1'}`}>{issueState.order_id || STATIC_ORDER}</span>
        </h1>
        <div>
          <ContainerValue>
            <strong>COSTE ESTIMADO RM</strong>
            <div className="placeholder-glow">
              <strong className={!loading ? null : 'placeholder rounded-2 mb-2'} style={{ fontWeight: '600', fontSize: '2rem', height: 15, marginBottom: 0, paddingBottom: 0 }}>
                {!loading && formatEUR(issueState.total_value || 0)}
              </strong>
            </div>
          </ContainerValue>
          <span style={{ position: 'absolute' }}>
            {issueState.ro_approve != null ? (
              <>
                <span style={{ fontSize: '20px', paddingLeft: '10%', textAlign: 'center', display: 'block' }}>{issueState?.ro_approve ? 'Aprobado' : 'Denegado'}</span>
                <span
                  style={{ textDecoration: 'underline', paddingLeft: '20%', display: 'block' }}
                  role="button"
                  onClick={e => {
                    let parsedState = { ...issueState, ro_approve: null };
                    setIssueState(parsedState);
                  }}
                >
                  Deshacer
                </span>
              </>
            ) : (
              issueState.planned_at != null &&
              userRole.toString().toUpperCase() === 'RO' && (
                <OkCancelGroup
                  txtOk={'APROBAR'}
                  id="ro_approve"
                  name="ro_approve"
                  onClickOk={e => {
                    let parsedState = { ...issueState, ro_approve: true, status: 'DONE' };
                    generateOrder(e, parsedState);
                  }}
                  onClickCancel={e => {
                    let parsedState = { ...issueState, ro_approve: false };
                    setIssueState(parsedState);
                    forceSave(e, parsedState, 'DENEGADO');
                  }}
                />
              )
            )}
          </span>
        </div>
        <div style={{ width: 'calc(100% + 1rem)', marginLeft: '-1rem' }}>
          <LastRowInfo className="placeholder-glow">
            <strong>NOMBRE RO:</strong>
            <span className={!loading ? null : 'placeholder ms-2 rounded-2'}>&nbsp; {(issueState.ro_name || user?.name).toUpperCase()}</span>
          </LastRowInfo>
          <LastRowInfo>
            <strong>CARGO:</strong>
            <span className={!loading ? null : 'placeholder ms-2 rounded-2'}> RESPONSABLE DE OPERACIONES</span>
          </LastRowInfo>
          <LastRowInfo>
            <strong className={!loading ? null : 'placeholder ms-2 rounded-2 w-50'}>{formatDate(issueState.created_at || new Date())}</strong>
          </LastRowInfo>
          <LastRowInfo>
            <strong className="pe-1">NOMBRE RM:</strong>
            <span className={!loading ? null : 'placeholder ms-2 rounded-2 w-50'}> {issueState?.rm_name?.toUpperCase() || ' '}</span>
            {isRM && parseInt(issueState.rm_id) !== parseInt(user.id) ? (
              <span
                className="ps-3 text-decoration-underline"
                role="button"
                id="rm_changed"
                onClick={e => {
                  let parsedState = { ...issueState, rm_id: user.id, rm_name: user.name };
                  setIssueState(parsedState);
                  forceSave(e, parsedState, user.name);
                }}
              >
                Tomar control
              </span>
            ) : null}
          </LastRowInfo>
        </div>
      </StyledBorderArticle>
    </StyledFirstPart>
  );
};

export default Article1;
