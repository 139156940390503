import styled from 'styled-components';
import { StyledHeading, SpanTitle, SpanNavigation, StyledSpan, StyledInputContainer, StyledEndHead } from './solid-heading.styles';

const SolidHeading = ({ title, children, ...rest }) => {
  return (
    <StyledHeading {...rest} className={'px-3 py-0 mb-4'}>
      <span className="align-items-center">{title && <SpanTitle className="fs-2">{title}</SpanTitle>}</span>
      <SpanNavigation style={{ width: !title ? '100%' : 'none' }}>{children}</SpanNavigation>
    </StyledHeading>
  );
};

SolidHeading.End = function SolidHeadingEnd({ children }) {
  return <StyledEndHead>{children}</StyledEndHead>;
};

SolidHeading.Span = function SolidHeadingSpan({ children, ...rest }) {
  return (
    <StyledSpan {...rest} className={'my-2'}>
      {children}
    </StyledSpan>
  );
};

SolidHeading.InputContainer = function SolidHeadingInputContainer({ children, label, options, onChange, defaultValue, selector = true, ...rest }) {
  return (
    // <div style={{display:'flex',flexDirection:'row', justifyContent: 'center', alignItems: 'center'}}>
    <div className="d-flex align-items-center">
      <SolidHeading.Span className="text-white">{label}</SolidHeading.Span>
      <StyledInputContainer selector={selector} {...rest} className="ms-2">
        {options && (
          <select name="zone" id="zone" role="button" onChange={onChange} value={defaultValue}>
            {options.map((v, i) => (
              <option key={v.id + '-' + i} value={v.id}>
                {v.name?.toString().toUpperCase()}
              </option>
            ))}
          </select>
        )}
        {children}
      </StyledInputContainer>
    </div>
  );
};

export default SolidHeading;
