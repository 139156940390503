import { BurgerLine, ClockContainer, Container, HamburgerMenu, Label, LogoContainer, LogoutLink, RoleContainer, SpanClock, SpanRole, SpanUser, SpanValue, SpanWelcome, SpanZone, SummaryContainer, TltSummary, UserContainer, Value } from './header.styles';
// ..hoooks
import { useTools } from '../../../hooks';
import { useEffect, useState } from 'react';
import { MenuBar } from '../';
import useAuth from '../../../hooks/useAuth';
import { Link } from 'react-router-dom';

export default function Header({ children, ...rest }) {
  return (
    <>
      <div className={`offcanvas offcanvas-end`} tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header">
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasRight" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <MenuBar />
        </div>
      </div>
      <Container {...rest}>
        {children}
        <HamburgerMenu className="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" role="button" aria-controls="offcanvasExample">
          <BurgerLine />
          <BurgerLine />
          <BurgerLine />
        </HamburgerMenu>
      </Container>
    </>
  );
}

Header.Logo = function HeaderLogo({ ...rest }) {
  return (
    <LogoContainer {...rest}>
      <img src="/img/plenoil-logo.svg" alt="Logo de Plenoil" />
    </LogoContainer>
  );
};

Header.User = function HeaderUser({ ...rest }) {
  const { logout, user } = useAuth();
  return (
    <UserContainer className="pb-2" {...rest}>
      <SpanWelcome>bienvenido/a</SpanWelcome>
      <SpanUser>
        <span>{user?.name || 'usuario plenoil invitado'}</span>
      </SpanUser>
      <LogoutLink onClick={logout}>desconectar</LogoutLink>
    </UserContainer>
  );
};

Header.Summary = function HeaderSummary({ ...rest }) {
  const { formatEUR } = useTools();
  const { user } = useAuth();
  return (
    <SummaryContainer {...rest}>
      <TltSummary>resumen total</TltSummary>
      <SpanValue>
        {user.zone.total_budget ? <Value className="text-primary fs-1 pe-3">{formatEUR(user.zone.total_budget || 0)}</Value> : ''}
        <Link to="/resumen" className="text-decoration-none overflow-hidden">
          <Label className={`text-primary ${user.zone.total_budget ? 'fs-6' : 'fs-5'}`}>Resumen estaciones</Label>
        </Link>
      </SpanValue>
    </SummaryContainer>
  );
};

Header.Clock = function HeaderClock({ ...rest }) {
  const date = new Date();
  const hour = date.getHours();
  const min = date.getMinutes();
  const [sec, setSec] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setSec(new Date().getSeconds().toLocaleString());
    }, 1000);
  }, []);

  return (
    <ClockContainer {...rest}>
      <SpanClock>
        {hour < 10 ? '0' + hour : hour}:{min < 10 ? '0' + min : min}:{sec < 10 ? '0' + sec : sec}
      </SpanClock>
    </ClockContainer>
  );
};

Header.Role = function HeaderRole({ ...rest }) {
  const { user } = useAuth();

  return (
    <RoleContainer {...rest}>
      <SpanRole>{user?.role || 'guest'}</SpanRole>
      <SpanZone>{user?.zone?.name || '--'}</SpanZone>
    </RoleContainer>
  );
};
